import { ConsoleLogger } from "@aws-amplify/core"
import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import * as api from "../../lib/api"
import moment from "moment"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "status" && "ステータス"}
        {props.formType === "title" && "タイトル"}
        {props.formType === "url" && "URL"}
        {props.formType === "date" && "日付"}
        {props.formType === "text" && "テキスト"}
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        {props.formType === "status" && (
          <ShowStatusForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "title" && (
          <ShowTitleForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "url" && (
          <ShowUrlForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "date" && (
          <ShowDateForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "text" && (
          <ShowTextForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "delete" && (
          <ShowDeleteForm
            notice={props.notice}
            show={props.show}
            getNotice={props.getNotice}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowStatusForm = (props: any) => {
  const [status, setStatus] = useState<number>(0)
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  useEffect(() => {
    setStatus(props.notice?.status || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleStatusUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const updateNotice = { ...props.notice, status: status }
      const json = await api.updateNoticeDb(updateNotice)
      console.log(json)
      props.getNotice(props.notice.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label>ステータス</Form.Label>
      <>
        <Form.Check
          id="notice-status"
          type="switch"
          checked={status == 1}
          onChange={handleStatusChange}
          label={status ? "有効" : "無効"}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleStatusUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowTitleForm = (props: any) => {
  const [title, setTitle] = useState("")
  const handleTitleChange = useCallback((e: any) => setTitle(e.target.value), [])

  useEffect(() => {
    setTitle(props.notice?.title || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleTitleUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      titleValid(title)
      const updateNotice = { ...props.notice, title: title }
      const json = await api.updateNoticeDb(updateNotice)
      console.log(json)
      props.getNotice(props.notice.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const titleValid = (title: string) => {
    if (!title) {
      throw new Error("タイトルは必須です")
    }
    if (title?.length > 100) {
      throw new Error("タイトルは100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>タイトル</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="title"
          value={title}
          placeholder="名前"
          onChange={handleTitleChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleTitleUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowUrlForm = (props: any) => {
  const [url, setUrl] = useState("")
  const handleUrlChange = useCallback((e: any) => setUrl(e.target.value), [])

  useEffect(() => {
    setUrl(props.notice?.url || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleUrlUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      urlValid(url)
      const updateNotice = { ...props.notice, url: url }
      const json = await api.updateNoticeDb(updateNotice)
      console.log(json)
      props.getNotice(props.notice.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const urlValid = (url: string) => {
    if (url?.length > 100) {
      throw new Error("URLは100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>URL</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="url"
          value={url}
          placeholder="URL"
          onChange={handleUrlChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleUrlUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDateForm = (props: any) => {
  const [date, setDate] = useState("")
  const handleDateChange = useCallback((e: any) => setDate(e.target.value), [])

  useEffect(() => {
    setDate(props.notice?.date || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleDateUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      dateValid(date)
      const updateNotice = { ...props.notice, date: date }
      const json = await api.updateNoticeDb(updateNotice)
      console.log(json)
      props.getNotice(props.notice.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const dateValid = (date: string) => {
    if (!date) {
      throw new Error("日付は必須です")
    }
    if (!moment(date).isValid()) {
      throw new Error("日付が不正です")
    }
  }

  return (
    <Form>
      <Form.Label>日付</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="date"
          name="date"
          id="date"
          value={date}
          placeholder="年月日"
          disabled={props.progress === Progress.Attempting}
          onChange={handleDateChange}
          maxLength={10}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleDateUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowTextForm = (props: any) => {
  const [text, setText] = useState("")
  const handleTextChange = useCallback((e: any) => setText(e.target.value), [])

  useEffect(() => {
    setText(props.notice?.text || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleTextUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      textValid(text)
      const updateNotice = { ...props.notice, text: text }
      const json = await api.updateNoticeDb(updateNotice)
      console.log(json)
      props.getNotice(props.notice.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const textValid = (text: string) => {
    if (text?.length > 2000) {
      throw new Error("テキストは2000文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>テキスト</Form.Label>
      <>
        <Form.Control
          size="sm"
          as="textarea"
          rows={10}
          type="text"
          name="comment"
          id="text"
          value={text}
          placeholder="テキスト"
          disabled={props.progress === Progress.Attempting}
          onChange={handleTextChange}
          maxLength={2000}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleTextUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDeleteForm = (props: any) => {
  const history = useNavigate()

  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const json = await api.delNoticeDb(props.notice.id)
      console.log(json)
      history(`/notice/`)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label>このお知らせを完全に削除しますか？</Form.Label>
      <>
        <Button
          size="sm"
          className="text-right mr-2 btn-secondary"
          onClick={handleCancel}
          disabled={props.progress === Progress.Attempting}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          className="text-right"
          variant="danger"
          onClick={handleDelete}
          disabled={props.progress === Progress.Attempting}
        >
          削除する
        </Button>
      </>
    </Form>
  )
}

export default App
