import React, { useCallback, useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import { Link } from "react-router-dom"
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill"
import { BsFillCaretUpFill } from "@react-icons/all-files/bs/BsFillCaretUpFill"
import * as api from "../../lib/api"
import { ICategory, ISortType } from "../../lib/interface"
import LinkTreeCategory from "./_link_tree_category"

function App() {
  const [allCategories, setAllCategories] = useState<ICategory[]>([])
  const [categories, setCategories] = useState<ICategory[]>([])
  const [searchText, setSearchText] = useState("")
  const [attempting] = useState(false)
  const [sortType, setSortType] = useState<ISortType>(null)

  useEffect(() => {
    getAllCategories()
  }, [])

  useEffect(() => {
    getCategories()
  }, [sortType])

  const getAllCategories = async () => {
    const json = await api.getCategoriesDb(searchText, sortType?.type, sortType?.order)
    setCategories(json?.data)
    setAllCategories(json?.data)
  }

  const getCategories = async () => {
    const json = await api.getCategoriesDb(searchText, sortType?.type, sortType?.order)
    console.log(json)
    setCategories(json?.data)
  }

  const handleNameChange = useCallback((e: any) => setSearchText(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    //typeが切り替わるタイミングで"asc"にする
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  return (
    <>
      <h1 className="h4">カテゴリ一覧</h1>
      <div className="m-3">
        {allCategories.length > 0 && (
          <LinkTreeCategory
            linkMode={true}
            allCategories={allCategories}
            topCategories={allCategories?.filter((category: ICategory) => {
              return !category.parentId
            })}
            targetIds={[]}
          />
        )}
      </div>
      <div className="mb-3">
        <Link to={`/category/create/`} className="btn-primary btn-sm btn">
          新規作成
        </Link>
      </div>
      <Form className="search-area mb-5">
        <div className="row">
          <div className="col-9 py-4">
            <Form.Group>
              <Form.Control placeholder="名前" type="text" disabled={attempting} onChange={handleNameChange} />
            </Form.Group>
          </div>
          <div className="col-2 d-flex align-items-center">
            <Button disabled={attempting} onClick={() => getCategories()} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <p>上位カテゴリが非公開のカテゴリはステータスがどの値でも公開されません</p>
      <div className="table-scroll">
        <Table hover className="admin-table">
          <thead className="text-center bg-light">
            <tr>
              <th></th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="view_number">
                  {sortType?.type === "view_number" && sortType?.order === "asc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                表示順
              </th>
              <th>名前</th>
              <th>所属</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="update_date">
                  {sortType?.type === "update_date" && sortType?.order === "asc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                更新日
              </th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="status">
                  {sortType?.type === "status" && sortType?.order === "asc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                ステータス
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {categories?.length > 0 &&
              categories?.map((category: ICategory, index: number) => {
                return (
                  <tr key={category.id}>
                    <td>{index + 1}</td>
                    <td>{category.viewNumber}</td>
                    <td>
                      <Link to={`/category/${category.id}/`} className="">
                        {category.name}
                      </Link>
                    </td>
                    <td>
                      {
                        categories?.find((parentCategory: ICategory, index: number) => {
                          return category.parentId === parentCategory.id
                        })?.name
                      }
                    </td>
                    <td>{category.updateDate}</td>
                    <td>
                      {category.status === 0 && <div className="text-danger">非公開</div>}
                      {category.status === 1 && <div className="text-info">ライセンスユーザ公開</div>}
                      {category.status === 2 && <div className="text-warning">ゲスト公開</div>}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default App
