import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../lib/auth";

function App(props: any) {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [userAttributes, setUserAttributes] = useState<any>(null);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (currentUser?.attributes?.identities) {
      const attr = JSON.parse(currentUser?.attributes?.identities);
      setUserAttributes(attr[0]);
    }
  }, [currentUser]);

  useEffect(() => {
    setCurrentUser(auth.currentUser);
  }, [auth]);

  return (
    <div className="header">
      {
        ShowSignoutBtn({
          auth,
          userAttributes
        })
      }
    </div >
  )
}

const ShowSignoutBtn = (props: any) => {
  if (props.auth.currentUser) {
    return (
      <Button variant="primary" href={`/auth/signout`} className="logout">
        LOGOUT
      </Button>
    )
  }
}

export default App;
