import Auth from "@aws-amplify/auth";
import axios from "axios";

export const request = async <T>(path: string, body?: any, opts?: any) => {
  //認証されているユーザを取得してくれる 手動でのリフレッシュトークンによる更新不要
  const user = await Auth.currentAuthenticatedUser();
  const accessToken = user?.signInUserSession.accessToken.jwtToken;
  let endPoint = process.env.REACT_APP_API_ENDPOINT;
  if (window.location.hostname === "localhost") {
    endPoint = process.env.REACT_APP_API_ENDPOINT_LOCAL;
  }

  return axios.post<T>(
    `${endPoint}${path}`,
    { ...body },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      ...opts,
    }
  );
};

export const isCancel = (err: any) => axios.isCancel(err);

export const getCancelTokenSource = () => axios.CancelToken.source();
