import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Spinner from "react-bootstrap/Spinner"
import * as api from "../../lib/api"
import { INotice } from "../../lib/interface"
import { textAddBr } from "../../lib/helper"

import FormModal from "./_form_modal"

function App() {
  const [notice, setNotice] = useState<INotice>(null)
  const params = useParams<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [formModalShow, setFormModalShow] = useState(false)
  //modalで表示するformのタイプを保持する
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    const id = params.id
    getNotice(id)
  }, [])

  const getNotice = async (id: string) => {
    try {
      const noticeJson = await api.getNoticeDb(id)
      setNotice(noticeJson.data)
      setIsLoaded(false)
    } catch (err) {
      console.log(err.message || err)
    }
  }

  const handleModalShow = (type: string) => {
    setFormType(type)
    setFormModalShow(true)
  }

  return (
    <React.Fragment>
      {isLoaded && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && notice && (
        <>
          <FormModal
            notice={notice}
            getNotice={getNotice}
            formType={formType}
            show={formModalShow}
            setFormModalShow={setFormModalShow}
            onHide={() => setFormModalShow(false)}
          />
          <h1 className="h4">お知らせ詳細</h1>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/notice/">お知らせ一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{notice?.title}</Breadcrumb.Item>
          </Breadcrumb>
          無効のお知らせのみ削除可能です。
          <Table className="user-table">
            <tbody>
              <tr>
                <th>ステータス</th>
                <td>
                  {notice.status ? <div className="text-info">有効</div> : <div className="text-danger">無効</div>}
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("status")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>タイトル</th>
                <td>{notice?.title}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("title")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>URL</th>
                <td>{notice?.url}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("url")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>日付</th>
                <td>{notice?.date}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("date")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>テキスト</th>
                <td>{textAddBr(notice?.text)}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("text")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>更新日</th>
                <td colSpan={2}>{notice?.updateDate}</td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-6 text-right">
              <Button href={`/notice/`} className="btn-secondary btn-sm">
                一覧へ戻る
              </Button>
            </div>
            <div className="col-6 text-right">
              {notice.status == 0 && (
                <Button
                  onClick={() => {
                    handleModalShow("delete")
                  }}
                  className="btn-sm btn-block"
                  variant="danger"
                >
                  削除
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default App
