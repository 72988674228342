import React, { useEffect, useState, useCallback } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import { useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import ProgressBar from "react-bootstrap/ProgressBar"
import * as api from "../../lib/api"
import { ICategory } from "../../lib/interface"

import SelectTreeCategory from "../category/_select_tree_category"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "status" && "ステータス"}
        {props.formType === "viewNumber" && "表示順"}
        {props.formType === "name" && "名前"}
        {props.formType === "hlsFile" && "動画ファイル"}
        {props.formType === "comment" && "コメント"}
        {props.formType === "thumbnailFile" && "サムネイル"}
        {props.formType === "category" && "所属"}
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        {props.formType === "status" && (
          <ShowStatusForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "viewNumber" && (
          <ShowViewNumberForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "name" && (
          <ShowNameForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "hlsFile" && (
          <ShowMovieFileForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "comment" && (
          <ShowCommentForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "thumbnailFile" && (
          <ShowThumbnailForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "category" && (
          <ShowCategoryForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "delete" && (
          <ShowDeleteForm
            movie={props.movie}
            show={props.show}
            getMovie={props.getMovie}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowStatusForm = (props: any) => {
  const [status, setStatus] = useState<number>(0)
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  useEffect(() => {
    setStatus(props.movie?.status || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleStatusUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const updateMovie = { ...props.movie, status: status }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label>ステータス</Form.Label>
      <>
        <Form.Check
          id="movie-status"
          type="switch"
          checked={status == 1}
          onChange={handleStatusChange}
          label={status ? "有効" : "無効"}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleStatusUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowViewNumberForm = (props: any) => {
  const [viewNumber, setViewNumber] = useState<number>()
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])

  useEffect(() => {
    setViewNumber(props.movie?.viewNumber || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleViewNumberUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      viewNumberValid(viewNumber)
      const updateMovie = { ...props.movie, viewNumber: viewNumber }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const viewNumberValid = (viewNumber: number) => {
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
  }

  return (
    <Form>
      <Form.Label>表示順</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="number"
          name="viewNumber"
          value={`${viewNumber}`}
          placeholder="表示順数値"
          disabled={props.progress === Progress.Attempting}
          onChange={handleViewNumberChange}
          maxLength={20}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleViewNumberUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowNameForm = (props: any) => {
  const [name, setName] = useState("")
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])

  useEffect(() => {
    setName(props.movie?.name || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleNameUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      nameValid(name)
      const updateMovie = { ...props.movie, name: name }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const nameValid = (name: string) => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>名前</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="name"
          value={name}
          placeholder="名前"
          onChange={handleNameChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleNameUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowMovieFileForm = (props: any) => {
  const [localMovieFile, setLocalMovieFile] = useState<any>()
  const [movieBarValue, setMovieBarValue] = useState(0)
  //動画をプレビューさせるなら必要
  const [hlsFile, setHlsFile] = useState("")

  useEffect(() => {
    setHlsFile(props.movie?.hlsFile || "")
    props.setErrorMessage("")
  }, [props.show])

  //下記プログレスバー処理
  const movieOptions = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      console.log(`${loaded}kb of ${total}kb : ${percent}%`)
      setMovieBarValue(percent)
    },
  }

  //動画アップロード処理 5Gまで
  const MAX_MOVIE_SIZE = 5368709120
  const MIN_MOVIE_SIZE = 0
  const onMovieDrop = async (acceptedFiles: any[]) => {
    if (acceptedFiles[0].type.includes("video/")) {
      if (acceptedFiles[0].size > MIN_MOVIE_SIZE && acceptedFiles[0].size <= MAX_MOVIE_SIZE) {
        setLocalMovieFile(acceptedFiles[0])
      } else {
        props.setErrorMessage("ファイルサイズは5Gまでです")
      }
    } else {
      props.setErrorMessage("ファイル形式が不正です")
    }
  }

  //ファイルのアップロード処理
  const fileUpload = async (file: any, type: string) => {
    try {
      const ext = file.name.match(/[^.]+$/)
      const filename = file.name.replace(/\s+/g, "_")
      let put_json = null
      put_json = await api.getMovieUploadUrl(filename, file.type, ext)
      if (put_json) {
        const url = put_json?.data
        //S3への一時アップロード
        if (type === "movie") {
          await api.putFile(url, file, movieOptions)
          const tempUrl = url.split("?Content-Type=")?.[0]
          return `movies/${tempUrl.split("/movies/")?.slice(-1)?.[0]}`
        }
      }
    } catch (err) {
      props.setErrorMessage(err)
    }
  }

  const handleMovieFileUpdate = async () => {
    props.setProgress(Progress.Preparing)
    try {
      movieFileValid(localMovieFile)
      const beforeJson = await api.updateMovieBeforeDb(props.movie?.id, "movie")
      console.log(beforeJson)
      const movieFile = await fileUpload(localMovieFile, "movie")
      const updateMovie = { ...props.movie, movieFile: movieFile }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const movieFileValid = (movieFile: string) => {
    if (!movieFile) {
      throw new Error("動画ファイルは必須です")
    }
  }

  const handleDelMovieFile = () => {
    setLocalMovieFile("")
  }

  return (
    <Form>
      {movieBarValue > 0 && movieBarValue < 100 && (
        <div className="progress-bar-area position-fixed bg-dark w-100 h-100 opacity-50 top-0 start-0 p-auto">
          <ProgressBar now={movieBarValue} label={`${movieBarValue}%`} />
        </div>
      )}
      <p className="text-muted m-0">アップロードする動画は5Gまで。MP4\MOV 形式</p>
      <Dropzone onDrop={onMovieDrop} accept={{ "video/*": [] }}>
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone border mb-3 text-center p-3">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="mt-3 small">
                動画をドラッグアンドドロップ、
                <br />
                または選択してアップロードしてください。
                <br />
              </p>
            </div>
          </section>
        )}
      </Dropzone>
      {localMovieFile && (
        <>
          選択中：{localMovieFile?.path}
          <Button
            size="sm"
            className="text-right"
            disabled={props.progress === Progress.Attempting}
            onClick={handleDelMovieFile}
          >
            削除
          </Button>
        </>
      )}
      {localMovieFile && (
        <>
          <Button
            size="sm"
            className="text-right"
            onClick={handleMovieFileUpdate}
            disabled={props.progress === Progress.Attempting}
          >
            変更
          </Button>
        </>
      )}
    </Form>
  )
}

const ShowCommentForm = (props: any) => {
  const [comment, setComment] = useState("")
  const handleCommentChange = useCallback((e: any) => setComment(e.target.value), [])

  useEffect(() => {
    setComment(props.movie?.comment || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleCommentUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      commentValid(comment)
      const updateMovie = { ...props.movie, comment: comment }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const commentValid = (comment: string) => {
    if (comment?.length > 2000) {
      throw new Error("コメントは2000文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>コメント</Form.Label>
      <>
        <Form.Control
          size="sm"
          as="textarea"
          rows={10}
          type="text"
          name="comment"
          id="comment"
          value={comment}
          placeholder="コメント"
          disabled={props.progress === Progress.Attempting}
          onChange={handleCommentChange}
          maxLength={2000}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleCommentUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowThumbnailForm = (props: any) => {
  const [thumbnailFile, setThumbnailFile] = useState<string>("")
  const [localThumbnailFile, setLocalThumbnailFile] = useState<any>()
  const [thumbnailNumber, setThumbnailNumber] = useState<number>(0)
  const [delThumbnailFileFlg, setDelThumbnailFileFlg] = useState<boolean>(false)
  const handleThumbnailNumber = (e: any) => {
    setThumbnailNumber(+e.target.value)
  }

  useEffect(() => {
    setThumbnailFile(props.movie?.thumbnailFile || null)
    setThumbnailNumber(props.movie?.thumbnailNumber || 0)
    props.setErrorMessage("")
  }, [props.show])

  //下記プログレスバー処理
  const thumbnailOptions = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      console.log(`${loaded}kb of ${total}kb : ${percent}%`)
    },
  }

  //サムネイルアップロード処理
  const MAX_THUMBNAIL_SIZE = 5242880
  const MIN_THUMBNAIL_SIZE = 0
  const onThumbnailDrop = async (acceptedFiles: any[]) => {
    if (acceptedFiles[0].type.includes("image/")) {
      if (acceptedFiles[0].size > MIN_THUMBNAIL_SIZE && acceptedFiles[0].size <= MAX_THUMBNAIL_SIZE) {
        setLocalThumbnailFile(acceptedFiles[0])
      } else {
        props.setErrorMessage("ファイルサイズは5Mまでです")
      }
    } else {
      props.setErrorMessage("ファイル形式が不正です")
    }
  }

  //ファイルのアップロード処理
  const fileUpload = async (file: any, type: string) => {
    try {
      const ext = file.name.match(/[^.]+$/)
      const filename = file.name.replace(/\s+/g, "_")
      let put_json = null
      put_json = await api.getThumbnailUploadUrl(filename, file.type, ext)
      if (put_json) {
        const url = put_json?.data.replace("", "")
        //S3への一時アップロード
        await api.putFile(url, file, thumbnailOptions)
        const tempUrl = url.split("?Content-Type=")?.[0]
        return `images/${tempUrl.split("/images/")?.slice(-1)?.[0]}`
      }
    } catch (err) {
      props.setErrorMessage(err)
    }
  }

  const handleThumbnailUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      let uploadThumbnailFile = thumbnailFile
      if (localThumbnailFile || delThumbnailFileFlg) {
        const beforeJson = await api.updateMovieBeforeDb(props.movie?.id, "thumbnail")
        console.log(beforeJson)
      }
      if (localThumbnailFile) {
        uploadThumbnailFile = await fileUpload(localThumbnailFile, "thumbnail")
      }
      const updateMovie = { ...props.movie, thumbnailFile: uploadThumbnailFile, thumbnailNumber: thumbnailNumber }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }
  //すでにアップロード前の選択されたファイルを削除する
  const handleDelLocalThumbnailFile = () => {
    setLocalThumbnailFile("")
  }
  //すでにアップロード済みのファイルを削除する
  const handleDelThumbnailFile = () => {
    setThumbnailFile("")
    setDelThumbnailFileFlg(true)
    if (thumbnailNumber === 0) {
      setThumbnailNumber(1)
    }
  }

  return (
    <Form>
      <p className="text-muted">アップロードする画像は5Mまで。PNG\JPEG\JPG\GIF 形式</p>
      <Dropzone accept={{ "image/*": [] }} onDrop={onThumbnailDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone border mb-3 text-center p-3">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="mt-3 small">
                画像をドラッグアンドドロップ、
                <br />
                または選択してアップロードしてください。
                <br />
              </p>
            </div>
          </section>
        )}
      </Dropzone>
      <div>
        {localThumbnailFile && (
          <>
            選択中：{localThumbnailFile.path}
            <Button
              size="sm"
              className="text-right"
              variant="danger"
              disabled={props.progress === Progress.Attempting}
              onClick={handleDelLocalThumbnailFile}
            >
              削除
            </Button>
          </>
        )}
        {thumbnailFile && !localThumbnailFile && (
          <Image
            className="img-thumbnail w-50"
            src={`https://${process.env.REACT_APP_DOMAIN}/${props.movie?.thumbnailFile}`}
            rounded
          />
        )}
        {thumbnailFile && !localThumbnailFile && (
          <>
            <Button
              size="sm"
              className="text-right"
              variant="danger"
              disabled={props.progress === Progress.Attempting}
              onClick={handleDelThumbnailFile}
            >
              削除
            </Button>
          </>
        )}
        {(thumbnailFile || localThumbnailFile) && (
          <Form.Check
            id="movie-thumbnailNumber-0"
            name="movie-thumbnailNumber-0"
            label="アップロード画像"
            type="radio"
            value={0}
            checked={thumbnailNumber === 0}
            onChange={handleThumbnailNumber}
          />
        )}
      </div>
      {props.movie?.hlsFile && (
        <div>
          <Image
            className="img-thumbnail w-50"
            src={`https://${process.env.REACT_APP_DOMAIN}/${props.movie?.hlsFile?.replace(
              "movies",
              "images"
            )}_thumbnail.0000000.jpg`}
            rounded
          />
          <Form.Check
            id="movie-thumbnailNumber-1"
            name="movie-thumbnailNumber-1"
            label="サムネイル画像1"
            type="radio"
            value={1}
            checked={thumbnailNumber === 1}
            onChange={handleThumbnailNumber}
          />
        </div>
      )}
      {props.movie?.hlsFile && (
        <div>
          <Image
            className="img-thumbnail w-50"
            src={`https://${process.env.REACT_APP_DOMAIN}/${props.movie?.hlsFile?.replace(
              "movies",
              "images"
            )}_thumbnail.0000001.jpg`}
            rounded
          />
          <Form.Check
            id="movie-thumbnailNumber-2"
            name="movie-thumbnailNumber-2"
            label="サムネイル画像2"
            type="radio"
            value={2}
            checked={thumbnailNumber === 2}
            onChange={handleThumbnailNumber}
          />
        </div>
      )}
      {props.movie?.hlsFile && (
        <div>
          <Image
            className="img-thumbnail w-50"
            src={`https://${process.env.REACT_APP_DOMAIN}/${props.movie?.hlsFile?.replace(
              "movies",
              "images"
            )}_thumbnail.0000002.jpg`}
            rounded
          />
          <Form.Check
            id="movie-thumbnailNumber-3"
            name="movie-thumbnailNumber-3"
            label="サムネイル画像3"
            type="radio"
            value={3}
            checked={thumbnailNumber === 3}
            onChange={handleThumbnailNumber}
          />
        </div>
      )}
      <Button
        size="sm"
        className="text-right"
        onClick={handleThumbnailUpdate}
        disabled={props.progress === Progress.Attempting}
      >
        変更
      </Button>
    </Form>
  )
}

const ShowCategoryForm = (props: any) => {
  const [allCategories, setAllCategories] = useState<ICategory[] | null>(null)
  const [categoryId, setCategoryId] = useState("")

  useEffect(() => {
    setCategoryId(props.movie?.categoryId || "")
    getCategories()
    props.setErrorMessage("")
  }, [props.show])

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    setAllCategories(json?.data)
  }

  const handleCategoryUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const updateMovie = { ...props.movie, categoryId: categoryId }
      const json = await api.updateMovieDb(updateMovie)
      console.log(json)
      props.getMovie(props.movie.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label>所属</Form.Label>
      <>
        <div className="m-3">
          <SelectTreeCategory
            allCategories={allCategories}
            topCategories={allCategories?.filter((category: ICategory, index: number) => {
              return category.parentId === ""
            })}
            targetIds={[categoryId]}
            disableIds={[props.movie.categoryId]}
            setSelectedId={setCategoryId}
          />
        </div>
        <Button
          size="sm"
          className="text-right"
          onClick={handleCategoryUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDeleteForm = (props: any) => {
  const history = useNavigate()

  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const json = await api.delMovieDb(props.movie.id)
      console.log(json)
      history(`/movie/`)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label>この動画を完全に削除しますか？</Form.Label>
      <>
        <Button
          size="sm"
          className="text-right mr-2 btn-secondary"
          onClick={handleCancel}
          disabled={props.progress === Progress.Attempting}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          className="text-right"
          variant="danger"
          onClick={handleDelete}
          disabled={props.progress === Progress.Attempting}
        >
          削除する
        </Button>
      </>
    </Form>
  )
}

export default App
