import React, { useState } from "react"

import { AuthProvider } from "./lib/auth"

import Pages from "./route"

function App() {
  const [isLoaded, setIsLoaded] = useState(false)

  return <AuthProvider onLoaded={setIsLoaded}>{isLoaded && <Pages />}</AuthProvider>
}

export default App
