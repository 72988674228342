import React from 'react'

// 文字列を改行コードで分割して改行タグに置換
export const textAddBr = (txt: string) => {
    const texts = txt.split(/(\n)/).map((item: string, index: number) => {
        return (
            <span key={`item-${index}`}>
                {item.match(/\n/) ? <br /> : item}
            </span>
        );
    });
    return texts
}