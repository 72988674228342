import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { AuthContext } from "./auth"
import { Header, Sidebar } from "../pages/common"

const signInPathName = "/auth/signin"

function App({ Component, ...rest }: any) {
  const auth = useContext(AuthContext)

  let route = <React.Fragment>{Component}</React.Fragment>

  if (rest.contents && rest.id != "auth-signin") {
    route = (
      <>
        <Row>
          <Col id="sidebar">
            <Sidebar />
          </Col>
          <Col id="main">
            <Header />
            <div id="contents">{route}</div>
          </Col>
        </Row>
      </>
    )
  }

  if (rest.id) {
    route = <div id={rest.id}>{route}</div>
  }

  // wrap
  route = (
    <React.Fragment>
      {/*(rest.wrap || rest.header) && <Header {...rest} />*/}
      {route}
      {/*(rest.wrap || rest.footer)*/}
    </React.Fragment>
  )

  // authenticate
  if (rest.auth) {
    if (!auth.isAuthenticated) {
      return (
        <Navigate
          to={{
            pathname: signInPathName,
          }}
        />
      )
    }
  }

  return route
}

export default App
