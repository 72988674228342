export interface IAccount {
  // Cognito
  Attributes?: IAccountAttributes[]
  Enabled?: boolean
  UserCreateDate?: Date
  UserLastModifiedDate?: Date
  UserStatus?: string
  Username?: string
  // RDS
  id?: string
  // cognitoId と Attributes 内の sub は同じ値
  cognitoId?: string
  name?: string
  userNo?: string
  licenseId?: string
  licenseName?: string
  updateDate?: string
  createDate?: string
  email?: string
  tempPassword?: string
  flag?: boolean
  nextId?: string
}

export interface IAccountAttributes {
  Name?: string
  Value?: string
}

export interface ILicense {
  id?: string
  name?: string
  viewNumber?: string
  status?: number
  updateDate?: string
  createDate?: string
  categories?: ICategory[]
  users?: IAccount[]
}

export interface ICategory {
  id?: string
  parentId?: string
  name?: string
  viewNumber?: string
  status?: number
  updateDate?: string
  createDate?: string
  movies?: IMovie[]
}

export interface IMovie {
  map: any
  id?: string
  categoryId?: string
  name?: string
  viewNumber?: string
  movieFile?: string
  hlsFile?: string
  comment?: string
  thumbnailFile?: string
  thumbnailNumber?: number
  playCount?: number
  playTime?: string
  status?: number
  updateDate?: string
  createDate?: string
  playProgressRate?: string
  movieName?: string
  categoryName?: string
  length?: number
}

export interface INotice {
  id?: string
  title?: string
  url?: string
  status?: number
  date?: string
  text?: string
  updateDate?: string
  createDate?: string
}

export interface ISortType {
  type?: string
  order?: string
}

export const validAccount = (
  userNo: string,
  email: string,
  name: string,
  password: string,
  licenseId?: string,
  allLicenses?: ILicense[]
) => {
  if (!userNo) {
    throw new Error("会員番号は必須です")
  }
  if (userNo?.length > 100) {
    throw new Error("会員番号は100文字以内で入力してください")
  }
  if (!email) {
    throw new Error("Emailは必須です")
  }
  if (email?.length > 100) {
    throw new Error("Emailは100文字以内で入力してください")
  }
  if (!password) {
    throw new Error("パスワードは必須です")
  }
  if (password?.length < 8 || password?.length > 20) {
    throw new Error("パスワードは8文字以上20文字以内で入力してください")
  }
  if (!name) {
    throw new Error("名前は必須です")
  }
  if (email?.length > 100) {
    throw new Error("名前は100文字以内で入力してください")
  }
  if (allLicenses && licenseId) {
    const allLicenseIds = allLicenses.map((license: ILicense) => {
      return license.id
    })
    if (!allLicenseIds.includes(licenseId)) {
      throw new Error("ライセンスが不正です")
    }
  }
}
