import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Spinner from "react-bootstrap/Spinner"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Admin } from "../../admin"
import * as api from "../../lib/api"
import { IAccount, IAccountAttributes, IMovie, ILicense } from "../../lib/interface"
import FormModal from "./_form_modal"
import Image from "react-bootstrap/Image"

function App() {
  const [adminLib, setAdminLib] = useState<Admin>()
  const [user, setUser] = useState<IAccount>(null)
  const params = useParams<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [formModalShow, setFormModalShow] = useState(false)
  const [historyMovies, setHistoryMovies] = useState<IMovie>(null)
  //modalで表示するformのタイプを保持する
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    new Admin(setAdminLib)
  }, [])

  useEffect(() => {
    if (adminLib && params.id) {
      getUser()
    }
  }, [adminLib])

  useEffect(() => {
    if (adminLib && params.id) {
      getHistoryMovies()
    }
  }, [adminLib])

  const getUser = async () => {
    try {
      const json = await api.getUserDb(params.id)
      if (json?.data) {
        const mapUser = await mappingUser(json?.data)
        setUser(mapUser)
      }
    } catch (err) {
      console.log(err)
    }
    setIsLoaded(false)
  }

  const getHistoryMovies = async () => {
    try {
      const json = await api.getHistoryMoviesDb(params.id)
      console.log(json?.data)
      if (json?.data) {
        setHistoryMovies(json?.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  console.log(historyMovies)

  const mappingUser = async (user: IAccount): Promise<IAccount> => {
    try {
      const cognitoJson = await adminLib.listUsers({
        Filter: `sub="${user.cognitoId}"`,
        Limit: 1,
      })
      let email = ""
      let sub = ""
      //もしCognitoの情報（emailやsubなど）が取得できないときには、認証情報がないデータとして削除を促す
      if (cognitoJson?.Users[0]) {
        sub = cognitoJson.Users[0].Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
        email = cognitoJson.Users[0].Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value
      }
      return { ...user, ...cognitoJson.Users[0], email: email, sub: sub }
    } catch (err) {
      console.log(err)
    }
  }

  const handleModalShow = (type: string) => {
    setFormType(type)
    setFormModalShow(true)
  }

  return (
    <React.Fragment>
      {isLoaded && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && user && (
        <>
          <FormModal
            adminLib={adminLib}
            user={user}
            getUser={getUser}
            formType={formType}
            show={formModalShow}
            setFormModalShow={setFormModalShow}
            onHide={() => setFormModalShow(false)}
          />
          <h1 className="h4">ユーザ詳細</h1>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/user/">ユーザ一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {user?.Attributes?.filter((v: any) => v.Name === "email")[0]?.Value}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Table className="user-table">
            <tbody>
              <tr>
                <th>状態</th>
                <td colSpan={2}>
                  {user?.Enabled && "有効"}
                  {!user.Enabled && "無効"}
                </td>
              </tr>
              <tr>
                <th>会員番号</th>
                <td>{user?.userNo}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("userNo")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{user?.email}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("email")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>名前</th>
                <td>{user?.name}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("name")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>ライセンス</th>
                <td>
                  <Link to={`/license/${user.licenseId}`} target="_blank" rel="noreferrer noopener">
                    {user.licenseName}
                  </Link>
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("license")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>登録日</th>
                <td colSpan={2}>{user?.createDate}</td>
              </tr>
              <tr>
                <th>更新日</th>
                <td colSpan={2}>{user?.updateDate}</td>
              </tr>
            </tbody>
          </Table>
          <br />
          <h4>視聴動画一覧</h4>
          <hr />
          {historyMovies && historyMovies.length > 1 && (
            <Table className="user-table">
              <tbody>
                {historyMovies?.map((historymovie: IMovie, index: number) => {
                  return (
                    <tr key={historymovie.id}>
                      <th colSpan={2}>
                        {index + 1}.{historymovie?.movieName}
                      </th>
                      <td>視聴日: {historymovie?.updateDate}</td>

                      {historymovie?.categoryName && <td colSpan={2}> {historymovie?.categoryName}</td>}

                      {historymovie?.hlsFile && historymovie?.thumbnailFile && (
                        <td>
                          <Link to={`/movie/${historymovie.id}`} target="_blank" rel="noreferrer noopener">
                            <Image
                              className="img-thumbnail"
                              src={`https://${process.env.REACT_APP_DOMAIN}/${historymovie?.thumbnailFile}`}
                              rounded
                              style={{ width: 120 }}
                            />
                          </Link>
                        </td>
                      )}

                      {historymovie?.hlsFile && !historymovie?.thumbnailFile && (
                        <td>
                          <Link to={`/movie/${historymovie.id}`} target="_blank" rel="noreferrer noopener">
                            <Image
                              className="img-thumbnail"
                              src={`https://${process.env.REACT_APP_DOMAIN}/${historymovie?.hlsFile?.replace(
                                "movies",
                                "images"
                              )}_thumbnail.0000000.jpg`}
                              rounded
                              style={{ width: 120 }}
                            />
                          </Link>
                        </td>
                      )}
                      <td colSpan={2}>　視聴経過率: {historymovie.playProgressRate} %</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
          {historyMovies && historyMovies.length === 0 && (
            <Table>
              <tbody>
                <div>
                  <p>視聴履歴はありません。</p>
                </div>
              </tbody>
            </Table>
          )}

          <div className="row">
            <div className="col-6 text-right">
              <Button href={`/user/`} className="btn-secondary btn-sm">
                一覧へ戻る
              </Button>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}
export default App
