import AWS from "aws-sdk"
import { Auth } from "aws-amplify"
import { IAccountAttributes } from "./interface"
import * as api from "./api"

class Admin {
  private provider: any
  public idToken: string = ""
  private callback: (data: any) => any

  constructor(__callback: (data: any) => any) {
    this.callback = __callback
    Auth.currentSession()
      .then((session: any) => {
        this.idToken = session.getIdToken().getJwtToken()
        this.setup()
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  private setup = () => {
    const region = process.env.REACT_APP_AWS_COGNITO_REGION as string
    const userPoolsId = process.env.REACT_APP_AWS_USER_POOLS_ID as string
    const identityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID as string
    const key = `cognito-idp.${region}.amazonaws.com/${userPoolsId}`

    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins: { [key]: this.idToken },
    })

    AWS.config.region = region
    AWS.config.credentials = credentials

    this.provider = new AWS.CognitoIdentityServiceProvider()

    this.callback(this)
  }

  public listAdmins = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.listUsers(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public deleteAdmin = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDeleteUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public enableAdmin = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminEnableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public disableAdmin = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDisableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public createAdmin = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminCreateUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public getUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminGetUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public updateUserAttributes = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminUpdateUserAttributes(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public listUsers = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.listUsers(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public deleteUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDeleteUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public enableUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminEnableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public disableUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDisableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public createUser = async (__params: any): Promise<any> => {
    //登録しようとしているメールアドレスがCognitoだけに登録されているかチェック
    await this.checkAlreadyRegistCognito(__params)
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminCreateUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public setUserPassword = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminSetUserPassword(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public generateRandomString = (num: number): string => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let result = ""
    const charactersLength = characters.length
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  public checkAlreadyRegistCognito = async (__params: any): Promise<any> => {
    //Cognitoに登録されているかチェック
    const alreadyFromCognitoParams = {
      Filter: `email="${__params.Username}"`,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    const alreadyCognito = await this.listUsers(alreadyFromCognitoParams)
    //すでにCognitoに同じメールアドレスで登録されている場合
    if (alreadyCognito.Users && alreadyCognito.Users.length > 0) {
      const sub = alreadyCognito.Users[0].Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
      const username = alreadyCognito.Users[0].Username
      //RDSに情報があるかチェック
      const json = await api.getUserDb(sub)
      //RDSに情報がない場合、Cognitoから削除
      if (!json.data?.id) {
        //Cognitoから削除
        const deleteFromCognitoParams = {
          Username: `${username}`,
          UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
        }
        await this.deleteUser(deleteFromCognitoParams)
        console.log("User Deleted!")
      }
    }
  }
}

export default Admin
