import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Button } from "react-bootstrap"

import { IAccount, IAccountAttributes } from "../../lib/interface"

import * as api from "../../lib/api"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)
  const [admins, setAdmins] = useState<IAccount[]>([])
  const [admin, setAdmin] = useState<IAccount>(null)
  const [pagenationToken, setPagenationToken] = useState()

  const handleListAdmins = async (pagenationToken?: string) => {
    const params = {
      PaginationToken: pagenationToken,
      Limit: 50,
    }
    try {
      const json = await props.adminLib.listAdmins(params)
      if (json) {
        if (admins) {
          setAdmins([...admins, ...json.Users])
        } else {
          setAdmins(json.Users)
        }
        setPagenationToken(json.PaginationToken)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        {props.formType === "delete" && (
          <ShowDeleteForm
            admin={props.admin}
            admins={props.admins}
            show={props.show}
            adminLib={props.adminLib}
            setAdmins={props.setAdmins}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowDeleteForm = (props: any) => {
  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const params = {
        Username: props.admin.Username,
      }
      const json = await props.adminLib?.deleteAdmin(params)
      props.setAdmins(
        props.admins?.filter((currentAdmin: IAccount) => {
          return currentAdmin.Username !== props.admin.Username
        })
      )
      console.log(json)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label className="fw-bold text-danger">この管理者を完全に削除しますか？</Form.Label>
      <p>{props.admin.Attributes.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value}</p>
      <hr />
      <Row className="text-center">
        <Col>
          <Button
            size="sm"
            className="text-right btn-secondary"
            onClick={handleCancel}
            disabled={props.progress === Progress.Attempting}
          >
            キャンセル
          </Button>
        </Col>
        <Col>
          <Button
            variant="danger"
            size="sm"
            className="text-end"
            onClick={handleDelete}
            disabled={props.progress === Progress.Attempting}
          >
            削除する
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default App
function setAdmins(arg0: any) {
  throw new Error("Function not implemented.")
}
