import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import { format } from "date-fns"
import { Admin } from "../../admin"
import { Link } from "react-router-dom"
import { IAccount, IAccountAttributes } from "../../lib/interface"
import FormModal from "./_form_modal"

function App() {
  const [admins, setAdmins] = useState<IAccount[]>([])
  const [admin, setAdmin] = useState<IAccount>(null)
  const [adminLib, setAdminLib] = useState<Admin>()
  const [pagenationToken, setPagenationToken] = useState()
  const [formModalShow, setFormModalShow] = useState(false)
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    new Admin(setAdminLib)
  }, [])

  useEffect(() => {
    if (adminLib) {
      handleListAdmins()
    }
  }, [adminLib])

  const handleListAdmins = async (pagenationToken?: string) => {
    const params = {
      PaginationToken: pagenationToken,
      Limit: 50,
    }
    try {
      const json = await adminLib.listAdmins(params)
      if (json) {
        if (admins) {
          setAdmins([...admins, ...json.Users])
        } else {
          setAdmins(json.Users)
        }
        setPagenationToken(json.PaginationToken)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleEnable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      const json = await adminLib?.enableAdmin(params)
      setAdmins(
        admins?.map((admin: IAccount) => {
          if (admin.Username === username) {
            admin.Enabled = true
          }
          return admin
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const handleDisable = async (username: string) => {
    console.log(username)
    const params = {
      Username: username,
    }
    try {
      const json = await adminLib?.disableAdmin(params)
      setAdmins(
        admins?.map((admin: IAccount) => {
          if (admin.Username === username) {
            admin.Enabled = false
          }
          return admin
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const handleModalShow = (type: string, currentAdmin: IAccount) => {
    setFormType(type)
    setAdmin(currentAdmin)
    setFormModalShow(true)
  }

  return (
    <>
      <FormModal
        admin={admin}
        admins={admins}
        formType={formType}
        show={formModalShow}
        setAdmins={setAdmins}
        adminLib={adminLib}
        setFormModalShow={setFormModalShow}
        onHide={() => setFormModalShow(false)}
      />
      <h1 className="h4">管理者一覧</h1>
      <div className="mb-3">
        <Link to={`/admin/create/`} className="btn-primary btn-sm btn">
          新規作成
        </Link>
      </div>
      <div className="table-scroll">
        <Table hover className="admin-table text-center">
          <thead className="text-center bg-light">
            <tr>
              <th></th>
              <th>Email</th>
              <th>登録日時</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {admins.length > 0 &&
              admins?.map((currentAdmin: IAccount, index: number) => {
                return (
                  <tr key={currentAdmin.Username}>
                    <td>{index + 1}</td>
                    <td>{currentAdmin.Attributes.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value}</td>
                    <td>{format(currentAdmin.UserCreateDate, "yyyy-MM-dd HH:mm:ss")}</td>
                    <td>
                      {currentAdmin.Enabled && (
                        <Button onClick={() => handleDisable(currentAdmin.Username)} className="btn-sm btn-block">
                          無効化
                        </Button>
                      )}
                      {!currentAdmin.Enabled && (
                        <Button
                          onClick={() => handleEnable(currentAdmin.Username)}
                          className="btn-sm btn-success btn-block"
                        >
                          有効化
                        </Button>
                      )}
                      {!currentAdmin.Enabled && (
                        <Button
                          onClick={() => handleModalShow("delete", currentAdmin)}
                          className="btn-sm btn-block"
                          variant="danger"
                        >
                          削除
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {pagenationToken && (
          <Button onClick={() => handleListAdmins(pagenationToken)} size="sm">
            NEXT
          </Button>
        )}
      </div>
    </>
  )
}

export default App
