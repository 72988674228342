import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { Modal } from "../common"
import * as api from "../../lib/api"
import { ILicense } from "../../lib/interface"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [name, setName] = useState("")
  const [licenses, setLicenses] = useState<ILicense[]>([])
  const [viewNumber, setViewNumber] = useState<number>()
  const [status, setStatus] = useState<number>(0)

  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  useEffect(() => {
    getLicenses()
  }, [])

  const getLicenses = async () => {
    const json = await api.getLicensesDb()
    console.log(json)
    setLicenses(json?.data)
  }

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async (e: any) => {
    setProgress(Progress.Preparing)
    try {
      licenseValid(name, viewNumber, status)
      const json = await api.createLicenseDb(name, viewNumber, status)
      console.log(json)
      history(`/license/`)
    } catch (err) {
      setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const licenseValid = (name: string, viewNumber: number, status: number) => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
    if (
      licenses
        ?.map((license: ILicense) => {
          return license.name
        })
        ?.includes(name)
    ) {
      throw new Error(name + " はすでに登録済みです")
    }
    if (!viewNumber && viewNumber !== 0) {
      throw new Error("表示順は必須です")
    }
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">ライセンス新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/license/">ライセンス一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ライセンス作成</Breadcrumb.Item>
      </Breadcrumb>
      <Table className="user-table">
        <tbody>
          <tr>
            <th className="align-middle w-25">名前</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="名前"
                  disabled={progress === Progress.Attempting}
                  onChange={handleNameChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th className="align-middle">表示順</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="number"
                  name="viewNumber"
                  value={`${viewNumber}`}
                  placeholder="表示順数値"
                  disabled={progress === Progress.Attempting}
                  onChange={handleViewNumberChange}
                  maxLength={20}
                  className="w-25"
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th className="align-middle">ステータス</th>
            <td>
              <Form>
                <Form.Check
                  id="license-status"
                  type="switch"
                  checked={status == 1}
                  onChange={handleStatusChange}
                  label={status ? "有効" : "無効"}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/license/`} className="btn-secondary  w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
