import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import { Modal } from "../common"
import moment from "moment"
import * as api from "../../lib/api"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [date, setDate] = useState("")
  const [text, setText] = useState("")
  const [status, setStatus] = useState<number>(0)

  const handleTitleChange = useCallback((e: any) => setTitle(e.target.value), [])
  const handleUrlChange = useCallback((e: any) => setUrl(e.target.value), [])
  const handleDateChange = useCallback((e: any) => setDate(e.target.value), [])
  const handleTextChange = useCallback((e: any) => setText(e.target.value), [])
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async (e: any) => {
    setProgress(Progress.Preparing)
    try {
      noticeValid(title, url, date, text)
      const json = await api.createNoticeDb(title, url, date, text, status)
      console.log(json)
      history(`/notice/`)
    } catch (err) {
      setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const noticeValid = (title: string, url: string, date: string, text: string) => {
    if (!title) {
      throw new Error("タイトルは必須です")
    }
    if (title?.length > 100) {
      throw new Error("タイトルは100文字以内で入力してください")
    }
    if (url?.length > 100) {
      throw new Error("URLは100文字以内で入力してください")
    }
    if (!date) {
      throw new Error("日付は必須です")
    }
    if (!moment(date).isValid()) {
      throw new Error("日付が不正です")
    }
    if (text?.length > 2000) {
      throw new Error("本文は2000文字以内で入力してください")
    }
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">お知らせ新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/notice/">お知らせ一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>お知らせ作成</Breadcrumb.Item>
      </Breadcrumb>
      <Table className="user-table">
        <tbody>
          <tr>
            <th className="w-25">タイトル</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="title"
                  value={title}
                  placeholder="名前"
                  disabled={progress === Progress.Attempting}
                  onChange={handleTitleChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>URL</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="url"
                  value={url}
                  placeholder="URL"
                  disabled={progress === Progress.Attempting}
                  onChange={handleUrlChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>日付</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="date"
                  name="date"
                  id="date"
                  value={date}
                  placeholder="年月日"
                  disabled={progress === Progress.Attempting}
                  onChange={handleDateChange}
                  maxLength={10}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>お知らせ</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  as="textarea"
                  rows={10}
                  type="text"
                  name="comment"
                  id="text"
                  value={text}
                  placeholder="お知らせ内容"
                  disabled={progress === Progress.Attempting}
                  onChange={handleTextChange}
                  maxLength={2000}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <Form>
                <Form.Check
                  id="notice-status"
                  type="switch"
                  checked={status == 1}
                  onChange={handleStatusChange}
                  label={status ? "有効" : "無効"}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/notice/`} className="btn-secondary  w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
