import { ConsoleLogger } from "@aws-amplify/core"
import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import * as api from "../../lib/api"
import { ICategory } from "../../lib/interface"
import SelectTreeCategory from "../category/_select_tree_category"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "status" && "ステータス"}
        {props.formType === "viewNumber" && "表示順"}
        {props.formType === "name" && "名前"}
        {props.formType === "categories" && "許可カテゴリ"}
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {props.formType === "status" && (
          <ShowStatusForm
            license={props.license}
            show={props.show}
            getLicense={props.getLicense}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "viewNumber" && (
          <ShowViewNumberForm
            license={props.license}
            show={props.show}
            getLicense={props.getLicense}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "name" && (
          <ShowNameForm
            license={props.license}
            show={props.show}
            getLicense={props.getLicense}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "categories" && (
          <ShowCategoryForm
            license={props.license}
            show={props.show}
            getLicense={props.getLicense}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "delete" && (
          <ShowDeleteForm
            license={props.license}
            show={props.show}
            getLicense={props.getLicense}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowStatusForm = (props: any) => {
  const [status, setStatus] = useState<number>(0)
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  useEffect(() => {
    setStatus(props.license?.status || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleStatusUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const updateLicense = { ...props.license, status: status }
      const json = await api.updateLicenseDb(updateLicense)
      console.log(json)
      props.getLicense(props.license.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label>ステータス</Form.Label>
      <>
        <Form.Check
          id="license-status"
          type="switch"
          checked={status == 1}
          onChange={handleStatusChange}
          label={status ? "有効" : "無効"}
        />
        <Button
          size="sm"
          className="mt-4"
          onClick={handleStatusUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowViewNumberForm = (props: any) => {
  const [viewNumber, setViewNumber] = useState<number>()
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])

  useEffect(() => {
    setViewNumber(props.license?.viewNumber || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleViewNumberUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      viewNumberValid(viewNumber)
      const updateLicense = { ...props.license, viewNumber: viewNumber }
      const json = await api.updateLicenseDb(updateLicense)
      console.log(json)
      props.getLicense(props.license.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const viewNumberValid = (viewNumber: number) => {
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
  }

  return (
    <Form>
      <Form.Label>表示順</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="number"
          name="viewNumber"
          value={`${viewNumber}`}
          placeholder="表示順数値"
          disabled={props.progress === Progress.Attempting}
          onChange={handleViewNumberChange}
          maxLength={20}
        />
        <Button
          size="sm"
          className="mt-4"
          onClick={handleViewNumberUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowNameForm = (props: any) => {
  const [name, setName] = useState("")
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])

  useEffect(() => {
    setName(props.license?.name || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleNameUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      nameValid(name)
      const updateLicense = { ...props.license, name: name }
      const json = await api.updateLicenseDb(updateLicense)
      console.log(json)
      props.getLicense(props.license.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const nameValid = (name: string) => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>名前</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="name"
          value={name}
          placeholder="名前"
          onChange={handleNameChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button size="sm" className="mt-4" onClick={handleNameUpdate} disabled={props.progress === Progress.Attempting}>
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowCategoryForm = (props: any) => {
  const [allCategories, setAllCategories] = useState<ICategory[]>([])
  const [categoryIds, setCategoryIds] = useState<string[]>([])

  useEffect(() => {
    setCategoryIds(
      props.license?.categories?.map((category: ICategory) => {
        return category.id
      })
    )
    getAllCategories()
    props.setErrorMessage("")
  }, [])

  const getAllCategories = async () => {
    try {
      const categoriesJson = await api.getCategoriesDb()
      setAllCategories(categoriesJson?.data)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    }
  }

  const handleSetCategoryId = async (id: string) => {
    //選択されたidがすでに含まれていたら、排除
    if (categoryIds?.includes(id)) {
      setCategoryIds(
        categoryIds.filter((categoryId: string) => {
          return categoryId != id
        })
      )
    } else {
      setCategoryIds([...categoryIds, id])
    }
  }

  const handleCategoriesUpdate = async () => {
    try {
      const json = await api.updateLicenseDb(props.license, categoryIds)
      console.log(json)
      props.getLicense(props.license.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label>許可カテゴリ</Form.Label>
      <>
        <div>
          <div>複数カテゴリを指定可能です</div>
          <div>
            <SelectTreeCategory
              allCategories={allCategories}
              topCategories={allCategories?.filter((category: ICategory, index: number) => {
                return !category.parentId
              })}
              targetIds={categoryIds}
              disableIds={[]}
              setSelectedId={handleSetCategoryId}
            />
          </div>
        </div>
        <Button
          size="sm"
          className="mt-4"
          onClick={handleCategoriesUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDeleteForm = (props: any) => {
  const history = useNavigate()

  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const json = await api.delLicenseDb(props.license.id)
      console.log(json)
      history(`/license/`)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label>このライセンスを完全に削除しますか？</Form.Label>
      <>
        <Button
          size="sm"
          className="text-right mr-2 btn-secondary"
          onClick={handleCancel}
          disabled={props.progress === Progress.Attempting}
        >
          キャンセル
        </Button>
        <Button
          size="sm"
          className="text-right"
          variant="danger"
          onClick={handleDelete}
          disabled={props.progress === Progress.Attempting}
        >
          削除する
        </Button>
      </>
    </Form>
  )
}

export default App
