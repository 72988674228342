import * as apigw from "./apigw"
import { ILicense, ICategory, IMovie, INotice, IAccount } from "./interface"
import axios from "axios"

export const createUserDb = async (
  cognitoId: string,
  userNo: string,
  name: string,
  licenseId: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/set-user", { cognitoId, userNo, name, licenseId }, opts)
  return res
}

export const getUserDb = async (cognitoId: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-user", { cognitoId }, opts)
  return res
}

export const getUsersDb = async (
  searchUserNo?: string,
  searchName?: string,
  type?: string,
  order?: string,
  nextId?: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/get-users", { searchUserNo, searchName, type, order, nextId }, opts)
  return res
}

export const getHistoryMoviesDb = async (cognitoId: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-history-movies", { cognitoId }, opts)
  return res
}

export const updateUserDb = async (cognitoId: string, detail: IAccount, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/update-user", { cognitoId, detail }, opts)
  return res
}

export const delUserDb = async (cognitoId: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-user", { cognitoId }, opts)
  return res
}

export const createLicenseDb = async (name: string, viewNumber: number, status: number, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/set-license", { name, viewNumber, status }, opts)
  return res
}

export const getLicenseDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-license", { id }, opts)
  return res
}

export const getLicensesDb = async (searchText?: string, type?: string, order?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-licenses", { searchText, type, order }, opts)
  return res
}

export const updateLicenseDb = async (license: ILicense, categoryIds?: string[], opts?: any): Promise<any> => {
  const id = license.id
  const status = license.status
  const viewNumber = license.viewNumber
  const name = license.name
  const res = await apigw.request("/admin/update-license", { id, status, viewNumber, name, categoryIds }, opts)
  return res
}

export const delLicenseDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-license", { id }, opts)
  return res
}

export const createCategoryDb = async (
  name: string,
  viewNumber: number,
  status: number,
  parentId: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/set-category", { name, viewNumber, status, parentId }, opts)
  return res
}

export const getCategoryDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-category", { id }, opts)
  return res
}

export const getCategoriesDb = async (searchText?: string, type?: string, order?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-categories", { searchText, type, order }, opts)
  return res
}

export const updateCategoryDb = async (category: ICategory, opts?: any): Promise<any> => {
  const id = category.id
  const parentId = category.parentId
  const status = category.status
  const viewNumber = category.viewNumber
  const name = category.name
  const res = await apigw.request("/admin/update-category", { id, parentId, status, viewNumber, name }, opts)
  return res
}

export const delCategoryDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-category", { id }, opts)
  return res
}

export const createMovieDb = async (
  categoryId: string,
  name: string,
  viewNumber: number,
  movieFile: string,
  comment: string,
  thumbnailFile: string,
  status: number,
  opts?: any
): Promise<any> => {
  const res = await apigw.request(
    "/admin/set-movie",
    { categoryId, name, viewNumber, movieFile, comment, thumbnailFile, status },
    opts
  )
  return res
}

export const getMovieDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-movie", { id }, opts)
  return res
}

export const getMoviesDb = async (
  searchText?: string,
  searchCategory?: string,
  type?: string,
  order?: string,
  nextId?: string,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/get-movies", { searchText, searchCategory, type, order, nextId }, opts)
  return res
}

export const updateMovieDb = async (movie: IMovie, opts?: any): Promise<any> => {
  const id = movie.id
  const categoryId = movie.categoryId
  const name = movie.name
  const viewNumber = movie.viewNumber
  const movieFile = movie.movieFile
  const comment = movie.comment
  const thumbnailNumber = movie.thumbnailNumber
  const thumbnailFile = movie.thumbnailFile
  const status = movie.status
  const res = await apigw.request(
    "/admin/update-movie",
    { id, categoryId, name, viewNumber, movieFile, comment, thumbnailNumber, thumbnailFile, status },
    opts
  )
  return res
}
//アップロード前に登録済みのファイルを削除しておく ファイルタイプにより動画かサムネイルを判定
export const updateMovieBeforeDb = async (id: string, fileType: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/update-movie-before", { id, fileType }, opts)
  return res
}

export const delMovieDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-movie", { id }, opts)
  return res
}

export const getThumbnailUploadUrl = async (name: string, type: string, ext: string) => {
  const res = await apigw.request<any>("/admin/get-thumbnail-upload-url", { name, type, ext })
  return res
}

export const getMovieUploadUrl = async (name: string, type: string, ext: string) => {
  const res = await apigw.request<any>("/admin/get-movie-upload-url", { name, type, ext })
  return res
}

export const putFile = async (url: string, file: any, opts?: any) => {
  return axios({
    method: "PUT",
    url: url,
    headers: {
      "Content-Type": file.type,
    },
    data: file,
    ...opts,
  })
}

export const createNoticeDb = async (
  title: string,
  url: string,
  date: string,
  text: string,
  status: number,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/admin/set-notice", { title, url, date, text, status }, opts)
  return res
}

export const getNoticeDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-notice", { id }, opts)
  return res
}

export const getNoticesDb = async (searchText?: string, type?: string, order?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/get-notices", { searchText, type, order }, opts)
  return res
}

export const updateNoticeDb = async (notice: INotice, opts?: any): Promise<any> => {
  const id = notice.id
  const status = notice.status
  const title = notice.title
  const url = notice.url
  const date = notice.date
  const text = notice.text
  const res = await apigw.request("/admin/update-notice", { id, status, title, url, date, text }, opts)
  return res
}

export const delNoticeDb = async (id?: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/admin/del-notice", { id }, opts)
  return res
}
