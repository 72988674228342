import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { Modal } from "../common"
import { Admin } from "../../admin"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [adminLib, setAdminLib] = useState<Admin>()
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [email, setEmail] = useState("")
  const handleEmailChange = useCallback((e: any) => setEmail(e.target.value), [])

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    new Admin(setAdminLib)
  }, [])

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async (e: any) => {
    setProgress(Progress.Preparing)
    if (!email) {
      setErrorMessage("Emailは必須です")
    }
    if (adminLib && email) {
      const params = {
        Username: email,
        MessageAction: "SUPPRESS",
        UserAttributes: [
          {
            Name: "email_verified",
            Value: "true",
          },
          {
            Name: "email",
            Value: email,
          },
        ],
      }
      try {
        const json = await adminLib.createAdmin(params)
        history(`/admin/`)
      } catch (err) {
        console.log(err)
        setErrorMessage(err)
        setProgress(Progress.Attempting)
      }
    }
    setProgress(Progress.Preparing)
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">管理者新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/user/">管理者一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>管理者新規作成</Breadcrumb.Item>
      </Breadcrumb>
      <Table className="user-table">
        <tbody>
          <tr>
            <th>Email</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  disabled={progress === Progress.Attempting}
                  onChange={handleEmailChange}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/admin/`} className="btn-secondary  w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
