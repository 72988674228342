import React from "react"
import { Link } from "react-router-dom"
import Row from "react-bootstrap/Row"
import { BsFillFolderFill } from "@react-icons/all-files/bs/BsFillFolderFill"
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight"
import { ICategory } from "../../lib/interface"

type CategoryTreeProp = {
  linkMode?: boolean
  //全てのカテゴリ
  allCategories: ICategory[]
  //トップカテゴリ
  topCategories?: ICategory[]
  //選択されているカテゴリID 複数対応のため配列
  targetIds: string[]
  //ShowChildCategoriesにおいてのループでのカレントカテゴリ
  currentCategory?: ICategory
  //深度カウント
  count?: number
}

function App(props: CategoryTreeProp) {
  const topCategories = props.topCategories
  const count = 1

  return (
    <>
      {topCategories?.map((topCategory: ICategory, index: number) => {
        return (
          <Row className="category-tree" key={`top-category-${count}-${index}`}>
            <div className="mb-2 bg-light p-2">
              {!props.targetIds?.includes(topCategory.id) && (
                <div className="category-dep mb-2">
                  <BsFillFolderFill size={20} className="text-secondary me-1" />
                  {props.linkMode && (
                    <Link to={`/category/${topCategory.id}`} className={`${count}`}>
                      {topCategory.name}
                    </Link>
                  )}
                  {!props.linkMode && <span>{topCategory.name}</span>}
                </div>
              )}
              {props.targetIds?.includes(topCategory.id) && (
                <>
                  <BsFillFolderFill size={24} className="text-secondary me-1" />
                  <span className={`dep dep-${count} targetCategory`}>{topCategory.name}</span>
                </>
              )}
              <div>
                <ShowChildCategories
                  linkMode={props.linkMode}
                  allCategories={props.allCategories}
                  currentCategory={topCategory}
                  count={count}
                  targetIds={props.targetIds}
                />
              </div>
            </div>
          </Row>
        )
      })}
    </>
  )
}

const ShowChildCategories = (props: CategoryTreeProp) => {
  const count = props.count + 1
  return (
    <>
      {props.allCategories
        ?.filter((childCategory: ICategory) => {
          return childCategory.parentId === props.currentCategory.id
        })
        ?.map((childCategory: ICategory, index: number) => {
          return (
            <div key={`childCategory-${count}-${index}`} className="dep" style={{ marginLeft: count * 10 + "px" }}>
              {!props.targetIds?.includes(childCategory.id) && (
                <>
                  <BsArrowReturnRight size={22} className="text-secondary me-1" />
                  {props.linkMode && (
                    <Link to={`/category/${childCategory.id}`} className={`dep-${count}`}>
                      {childCategory.name}
                    </Link>
                  )}
                  {!props.linkMode && <span>{childCategory.name}</span>}
                </>
              )}
              {props.targetIds?.includes(childCategory.id) && (
                <>
                  <BsArrowReturnRight size={24} className="me-1 text-secondary" />
                  <span className={`dep dep-${count} targetCategory`}>{childCategory.name}</span>
                </>
              )}
              <ShowChildCategories
                linkMode={props.linkMode}
                allCategories={props.allCategories}
                currentCategory={childCategory}
                count={count}
                targetIds={props.targetIds}
              />
            </div>
          )
        })}
    </>
  )
}

export default App
