import React, { useEffect, useState, useCallback } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link, useNavigate } from "react-router-dom"
import * as api from "../../lib/api"
import { ICategory } from "../../lib/interface"

import SelectTreeCategory from "./_select_tree_category"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "status" && "ステータス"}
        {props.formType === "viewNumber" && "表示順"}
        {props.formType === "name" && "名前"}
        {props.formType === "parent" && "所属"}
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        {props.formType === "status" && (
          <ShowStatusForm
            category={props.category}
            show={props.show}
            getCategory={props.getCategory}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "viewNumber" && (
          <ShowViewNumberForm
            category={props.category}
            show={props.show}
            getCategory={props.getCategory}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "name" && (
          <ShowNameForm
            category={props.category}
            show={props.show}
            getCategory={props.getCategory}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "parent" && (
          <ShowParentForm
            category={props.category}
            show={props.show}
            getCategory={props.getCategory}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "delete" && (
          <ShowDeleteForm
            category={props.category}
            show={props.show}
            getCategory={props.getCategory}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowStatusForm = (props: any) => {
  const [status, setStatus] = useState<number>(0)
  const handleStatusChange = (e: any) => {
    setStatus(+e.target.value)
  }

  useEffect(() => {
    setStatus(props.category?.status || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleStatusUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const updateCategory = { ...props.category, status: status }
      const json = await api.updateCategoryDb(updateCategory)
      console.log(json)
      props.getCategory(props.category.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  return (
    <Form>
      <Form.Label className="fw-bold">ステータス</Form.Label>
      <>
        <Form.Check
          id="category-status-0"
          name="category-status-0"
          label="非公開"
          type="radio"
          value={0}
          checked={!status}
          onChange={handleStatusChange}
        />
        <Form.Check
          id="category-status-1"
          name="category-status-1"
          label="ライセンスユーザ公開"
          type="radio"
          value={1}
          checked={status === 1}
          onChange={handleStatusChange}
        />
        <Form.Check
          id="category-status-2"
          name="category-status-2"
          label="ゲスト公開"
          type="radio"
          value={2}
          checked={status === 2}
          onChange={handleStatusChange}
        />
        <hr />
        <Button
          size="sm"
          className="text-right"
          onClick={handleStatusUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowViewNumberForm = (props: any) => {
  const [viewNumber, setViewNumber] = useState<number>()
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])

  useEffect(() => {
    setViewNumber(props.category?.viewNumber || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleViewNumberUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      viewNumberValid(viewNumber)
      const updateCategory = { ...props.category, viewNumber: viewNumber }
      const json = await api.updateCategoryDb(updateCategory)
      console.log(json)
      props.getCategory(props.category.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const viewNumberValid = (viewNumber: number) => {
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
  }

  return (
    <Form>
      <Form.Label className="fw-bold">表示順</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="number"
          name="viewNumber"
          value={`${viewNumber}`}
          placeholder="表示順数値"
          disabled={props.progress === Progress.Attempting}
          onChange={handleViewNumberChange}
          maxLength={20}
        />
        <hr />
        <Button
          size="sm"
          className="text-right"
          onClick={handleViewNumberUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowNameForm = (props: any) => {
  const [name, setName] = useState("")
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])

  useEffect(() => {
    setName(props.category?.name || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleNameUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      nameValid(name)
      const updateCategory = { ...props.category, name: name }
      const json = await api.updateCategoryDb(updateCategory)
      console.log(json)
      props.getCategory(props.category.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const nameValid = (name: string) => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label className="fw-bold">名前</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="name"
          value={name}
          placeholder="名前"
          onChange={handleNameChange}
          disabled={props.progress === Progress.Attempting}
        />
        <hr />

        <Button
          size="sm"
          className="text-right"
          onClick={handleNameUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}
//所属カテゴリ用フォーム
const ShowParentForm = (props: any) => {
  const [allCategories, setAllCategories] = useState<ICategory[] | null>(null)
  const [parentId, setParentId] = useState("")

  useEffect(() => {
    setParentId(props.category?.parentId || "")
    getCategories()
    props.setErrorMessage("")
  }, [props.show])

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    setAllCategories(json?.data)
  }

  const handleParentUpdate = async () => {
    props.setProgress(Progress.Attempting)
    try {
      parentValid(parentId)
      const updateCategory = { ...props.category, parentId: parentId }
      const json = await api.updateCategoryDb(updateCategory)
      console.log(json)
      props.getCategory(props.category.id)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const parentValid = (name: string) => {}

  return (
    <Form>
      <Form.Label className="fw-bold">所属</Form.Label>
      <div>所属を変更しても紐づけられたライセンスは変更されません</div>
      <>
        <div className="m-3">
          <div className="m-2 category-tree">
            <Link to="#" onClick={() => setParentId("")}>
              {parentId === "" && <span className={`dep targetCategory`}>トップカテゴリ</span>}
              {parentId != "" && <span className={`dep`}>トップカテゴリ</span>}
            </Link>
          </div>
          <SelectTreeCategory
            allCategories={allCategories}
            topCategories={allCategories?.filter((category: ICategory, index: number) => {
              return !category.parentId
            })}
            targetIds={[parentId]}
            disableIds={[props.category.id]}
            setSelectedId={setParentId}
          />
        </div>
        <hr />
        <Button
          size="sm"
          className="text-right"
          onClick={handleParentUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDeleteForm = (props: any) => {
  const history = useNavigate()

  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    try {
      const json = await api.delCategoryDb(props.category.id)
      console.log(json)
      history(`/category/`)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label className="fw-bold text-danger">このカテゴリを完全に削除しますか？</Form.Label>
      <hr />
      <Row className="text-center">
        <Col>
          <Button
            size="sm"
            className="text-right btn-secondary"
            onClick={handleCancel}
            disabled={props.progress === Progress.Attempting}
          >
            キャンセル
          </Button>
        </Col>
        <Col>
          <Button
            variant="danger"
            size="sm"
            className="text-end"
            onClick={handleDelete}
            disabled={props.progress === Progress.Attempting}
          >
            削除する
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default App
