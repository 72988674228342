import React from "react"
import { ICategory } from "../../lib/interface"
import { Link } from "react-router-dom"
import { BsFillFolderFill } from "@react-icons/all-files/bs/BsFillFolderFill"
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight"

type CategoryTreeProp = {
  //全てのカテゴリ
  allCategories?: ICategory[]
  //トップカテゴリ
  topCategories?: ICategory[]
  //カレントカテゴリ
  currentCategory?: ICategory
  //選択されているカテゴリID 複数対応のため配列
  targetIds?: string[]
  //選択させないカテゴリID 複数対応のため配列
  disableIds?: string[]
  //選択されたときにCategoryIDを設定するSetStateAction 単数と複数の二つ準備
  setSelectedId?: React.Dispatch<React.SetStateAction<string>>
  //深度カウント
  count?: number
  //カテゴリ選択を5階層までにするための定義
  setDepth?: React.Dispatch<React.SetStateAction<number>>
}

function App(props: CategoryTreeProp) {
  const topCategories = props.topCategories
  const count = 1

  const handleChangeParent = (id: string) => {
    if (props.setDepth) {
      props.setDepth(count)
    }
    if (props.setSelectedId) {
      props.setSelectedId(id)
    }
  }

  return (
    <div className="category-tree">
      {topCategories?.map((topCategory: ICategory, index: number) => {
        return (
          <div key={`category-${index}`} className="mb-2 bg-light p-2">
            {!props.targetIds?.includes(topCategory.id) && !props.disableIds?.includes(topCategory.id) && (
              <div className="category-dep mb-2">
                <BsFillFolderFill size={20} className="text-secondary me-1" />
                <Link to="#" className={`dep-${count}`} onClick={() => handleChangeParent(topCategory.id)}>
                  {topCategory.name}
                </Link>
              </div>
            )}
            {props.targetIds?.includes(topCategory.id) && !props.disableIds?.includes(topCategory.id) && (
              <>
                <BsFillFolderFill size={22} className="text-secondary me-1" />
                <Link to="#" className={`dep-${count}`} onClick={() => handleChangeParent(topCategory.id)}>
                  <span className={`dep dep-${count} targetCategory`}>{topCategory.name}</span>
                </Link>
              </>
            )}
            {props.disableIds?.includes(topCategory.id) && (
              <>
                <BsFillFolderFill size={22} className="text-secondary me-1" />
                <span className={`dep dep-${count} text-warning`}>{topCategory.name}</span>
              </>
            )}
            <div>
              <ShowChildCategories
                allCategories={props.allCategories}
                currentCategory={topCategory}
                count={count}
                targetIds={props.targetIds}
                disableIds={props.disableIds}
                setSelectedId={props.setSelectedId}
                setDepth={props.setDepth}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const ShowChildCategories = (props: CategoryTreeProp) => {
  const count = props.count + 1

  const handleChangeParent = (id: string) => {
    if (props.setDepth) {
      props.setDepth(count)
    }
    if (props.setSelectedId) {
      props.setSelectedId(id)
    }
  }

  return (
    <>
      {props.allCategories
        ?.filter((childCategory: ICategory) => {
          return childCategory.parentId === props.currentCategory.id
        })
        ?.map((childCategory: ICategory, index: number) => {
          return (
            <div key={`childCategory-${childCategory.id}`} className="dep" style={{ marginLeft: count * 10 + "px" }}>
              {!props.targetIds?.includes(childCategory.id) && !props.disableIds?.includes(childCategory.id) && (
                <>
                  <BsArrowReturnRight size={22} className="text-secondary me-1" />
                  <Link to="#" className={`dep dep-${count}`} onClick={() => handleChangeParent(childCategory.id)}>
                    {childCategory.name}
                  </Link>
                </>
              )}
              {props.targetIds?.includes(childCategory.id) && !props.disableIds?.includes(childCategory.id) && (
                <>
                  <BsArrowReturnRight size={22} className="text-secondary me-1" />
                  <Link to="#" className={`dep dep-${count}`} onClick={() => handleChangeParent(childCategory.id)}>
                    <span className={`dep dep-${count} targetCategory`}>{childCategory.name}</span>
                  </Link>
                </>
              )}
              {props.disableIds?.includes(childCategory.id) && (
                <>
                  <BsArrowReturnRight size={22} className="text-secondary me-1" />
                  <span className={`dep dep-${count} text-warning`}>{childCategory.name}</span>
                </>
              )}
              <ShowChildCategories
                allCategories={props.allCategories}
                currentCategory={childCategory}
                count={count}
                targetIds={props.targetIds}
                disableIds={props.disableIds}
                setSelectedId={props.setSelectedId}
                setDepth={props.setDepth}
              />
            </div>
          )
        })}
    </>
  )
}

export default App
