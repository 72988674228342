import React from "react"
import { Link } from "react-router-dom"
import { Nav } from "react-bootstrap"

function App(props: any) {
  const path = window.document.location.pathname
  const home_class = (path.match(/^\/$/)) ? " active" : ""
  const admin_class = (path.match(/^\/admin/)) ? " active" : ""
  const user_class = (path.match(/^\/user/)) ? " active" : ""
  const license_class = (path.match(/^\/license/)) ? " active" : ""
  const category_class = (path.match(/^\/category/)) ? " active" : ""
  const movie_class = (path.match(/^\/movie/)) ? " active" : ""
  const notice_class = (path.match(/^\/notice/)) ? " active" : ""

  return (
    <>
      <div className="header">
        <img src="/img/logo.png" alt="Surf Nation Japan" />
      </div>
      <Nav className="flex-column">
        <Nav.Link as={Link} to="/" className={home_class}>
          <div className="nav-icon home"></div>ホーム
        </Nav.Link>
        <Nav.Link as={Link} to="/admin/" className={admin_class}>
          <div className="nav-icon admin"></div>管理者管理
        </Nav.Link>
        <Nav.Link as={Link} to="/user/" className={user_class}>
          <div className="nav-icon user"></div>ユーザ管理
        </Nav.Link>
        <Nav.Link as={Link} to="/license/" className={license_class}>
          <div className="nav-icon license"></div>ライセンス管理
        </Nav.Link>
        <Nav.Link as={Link} to="/category/" className={category_class}>
          <div className="nav-icon category"></div>カテゴリ管理
        </Nav.Link>
        <Nav.Link as={Link} to="/movie/" className={movie_class}>
          <div className="nav-icon movie"></div>動画管理
        </Nav.Link>
        <Nav.Link as={Link} to="/notice/" className={notice_class}>
          <div className="nav-icon notice"></div>お知らせ管理
        </Nav.Link>
      </Nav>
    </>
  )
}

export default App;
