import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import ProgressBar from "react-bootstrap/ProgressBar"
import Dropzone from "react-dropzone"
import { Modal } from "../common"
import * as api from "../../lib/api"
import { ICategory } from "../../lib/interface"

import SelectTreeCategory from "../category/_select_tree_category"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [categoryId, setCategoryId] = useState("")
  const [name, setName] = useState("")
  const [viewNumber, setViewNumber] = useState<number>(1)
  const [localMovieFile, setLocalMovieFile] = useState<any>()
  const [comment, setComment] = useState("")
  const [localThumbnailFile, setLocalThumbnailFile] = useState<any>()
  const [status, setStatus] = useState<number>(1)
  const [allCategories, setAllCategories] = useState<ICategory[]>([])
  const [movieBarValue, setMovieBarValue] = useState(0)
  const [thumbnailBarValue, setThumbnailBarValue] = useState(0)

  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])
  const handleCommentChange = useCallback((e: any) => setComment(e.target.value), [])
  const handleStatusChange = () => {
    setStatus(status == 0 ? 1 : 0)
  }

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    getCategories()
    setErrorMessage("")
  }, [])

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    setAllCategories(json?.data)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async (e: any) => {
    setProgress(Progress.Attempting)
    try {
      movieValid()
      const movieFile = await fileUpload(localMovieFile, "movie")
      let thumbnailFile = ""
      if (localThumbnailFile) {
        thumbnailFile = await fileUpload(localThumbnailFile, "thumbnail")
      }
      const json = await api.createMovieDb(categoryId, name, viewNumber, movieFile, comment, thumbnailFile, status)
      console.log(json)
      history(`/movie/`)
    } catch (err) {
      setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const movieValid = () => {
    if (!categoryId) {
      throw new Error("所属は必須です")
    }
    if (
      categoryId &&
      !allCategories
        ?.map((category: ICategory) => {
          return category.id
        })
        ?.includes(categoryId)
    ) {
      throw new Error("所属が不正です")
    }
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
    if (!viewNumber && viewNumber !== 0) {
      throw new Error("表示順は必須です")
    }
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
    if (!localMovieFile) {
      throw new Error("動画ファイルは必須です")
    }
    if (comment && comment?.length > 2000) {
      throw new Error("コメントは2000文字以内で入力してください")
    }
  }
  //下記プログレスバー処理
  const movieOptions = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      console.log(`${loaded}kb of ${total}kb : ${percent}%`)
      setMovieBarValue(percent)
    },
  }

  const thumbnailOptions = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      console.log(`${loaded}kb of ${total}kb : ${percent}%`)
      setThumbnailBarValue(percent)
    },
  }

  //動画アップロード処理 5Gまで
  const MAX_MOVIE_SIZE = 5368709120
  const MIN_MOVIE_SIZE = 0
  const validExtensions = [".mp4", ".mov"]
  const onMovieDrop = async (acceptedFiles: File[]) => {
    console.log("fileType", acceptedFiles[0].type)
    console.log("fileExtension", acceptedFiles[0].name.slice(acceptedFiles[0].name.lastIndexOf(".")).toLowerCase())

    // ファイルの拡張子を取得
    const fileExtension = acceptedFiles[0].name.slice(acceptedFiles[0].name.lastIndexOf(".")).toLowerCase();
    if (validExtensions.includes(fileExtension)) {
      if (acceptedFiles[0].size > MIN_MOVIE_SIZE && acceptedFiles[0].size <= MAX_MOVIE_SIZE) {
        setLocalMovieFile(acceptedFiles[0])
      } else {
        setErrorMessage("ファイルサイズは5Gまでです")
      }
    } else {
      setErrorMessage("ファイル形式が不正です")
    }
  }

  //サムネイルアップロード処理
  const MAX_THUMBNAIL_SIZE = 5242880
  const MIN_THUMBNAIL_SIZE = 0
  const onThumbnailDrop = async (acceptedFiles: any[]) => {
    if (acceptedFiles[0].type.includes("image/")) {
      if (acceptedFiles[0].size > MIN_THUMBNAIL_SIZE && acceptedFiles[0].size <= MAX_THUMBNAIL_SIZE) {
        setLocalThumbnailFile(acceptedFiles[0])
      } else {
        setErrorMessage("ファイルサイズは5Mまでです")
      }
    } else {
      setErrorMessage("ファイル形式が不正です")
    }
  }
  //ファイルのアップロード処理
  const fileUpload = async (file: any, type: string) => {
    try {
      const ext = file.name.match(/[^.]+$/)
      const filename = file.name.replace(/\s+/g, "_")
      let put_json = null
      // S3の認証済みアップロードURLを取得する サムネイルと動画それぞれ別のアップロードURLとなる
      if (type === "thumbnail") {
        put_json = await api.getThumbnailUploadUrl(filename, file.type, ext)
      } else if (type === "movie") {
        put_json = await api.getMovieUploadUrl(filename, file.type, ext)
      }
      if (put_json) {
        const url = put_json?.data
        //S3への一時アップロード
        if (type === "movie") {
          await api.putFile(url, file, movieOptions)
          const tempUrl = url.split("?Content-Type=")?.[0]
          return `movies/${tempUrl.split("/movies/")?.slice(-1)?.[0]}`
        } else {
          await api.putFile(url, file, thumbnailOptions)
          const tempUrl = url.split("?Content-Type=")?.[0]
          return `images/${tempUrl.split("/images/")?.slice(-1)?.[0]}`
        }
      }
    } catch (err) {
      setErrorMessage(err)
    }
  }

  const handleDelMovieFile = () => {
    setLocalMovieFile("")
  }

  const handleDelThumbnailFile = () => {
    setLocalThumbnailFile("")
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">動画新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/movie/">動画一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>動画作成</Breadcrumb.Item>
      </Breadcrumb>
      {movieBarValue > 0 && progress === Progress.Attempting && (
        <div className="progress-bar-area position-fixed bg-dark w-100 h-100 opacity-50 top-0 start-0 p-auto">
          <ProgressBar now={movieBarValue} label={`${movieBarValue}%`} />
        </div>
      )}
      {thumbnailBarValue > 0 && progress === Progress.Attempting && (
        <div className="progress-bar-area position-fixed bg-dark w-100 h-100 opacity-50 top-0 start-0 p-auto">
          <ProgressBar now={thumbnailBarValue} label={`${thumbnailBarValue}%`} />
        </div>
      )}
      <Table className="movie-table">
        <tbody>
          <tr>
            <th>所属</th>
            <td>
              <Form>
                <div className="m-3">
                  <SelectTreeCategory
                    allCategories={allCategories}
                    topCategories={allCategories?.filter((category: ICategory, index: number) => {
                      return category.parentId === ""
                    })}
                    targetIds={[categoryId]}
                    setSelectedId={setCategoryId}
                  />
                </div>
              </Form>
            </td>
          </tr>
          <tr>
            <th>名前</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="名前"
                  disabled={progress === Progress.Attempting}
                  onChange={handleNameChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>表示順</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="number"
                  name="viewNumber"
                  value={`${viewNumber || 0}`}
                  placeholder="表示順数値"
                  disabled={progress === Progress.Attempting}
                  onChange={handleViewNumberChange}
                  maxLength={20}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>動画ファイル</th>
            <td>
              <Form>
                <p className="text-muted m-0">アップロードする動画は5Gまで。MP4\MOV 形式</p>
                <Dropzone onDrop={onMovieDrop} accept={{ "video/*": [] }}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone border mb-3 text-center p-3">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="mt-3 small">
                          動画をドラッグアンドドロップ、
                          <br />
                          または選択してアップロードしてください。
                          <br />
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {localMovieFile && (
                  <>
                    選択中：{localMovieFile?.path}
                    <Button
                      size="sm"
                      className="text-right"
                      variant="danger"
                      disabled={progress === Progress.Attempting}
                      onClick={handleDelMovieFile}
                    >
                      削除
                    </Button>
                  </>
                )}
              </Form>
            </td>
          </tr>
          <tr>
            <th>コメント</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  as="textarea"
                  rows={10}
                  type="text"
                  name="comment"
                  id="comment"
                  value={comment}
                  placeholder="コメント"
                  disabled={progress === Progress.Attempting}
                  onChange={handleCommentChange}
                  maxLength={2000}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>サムネイル画像</th>
            <td>
              <Form>
                <p className="text-muted m-0">アップロードする画像は5Mまで。PNG\JPEG\JPG\GIF 形式</p>
                <p className="text-muted m-0">
                  サムネイル画像をアップロードしない場合、自動作成されたサムネイルが設定されます
                </p>
                <Dropzone accept={{ "image/*": [] }} onDrop={onThumbnailDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone border mb-3 text-center p-3">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="mt-3 small">
                          画像をドラッグアンドドロップ、
                          <br />
                          または選択してアップロードしてください。
                          <br />
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {localThumbnailFile && (
                  <>
                    選択中：{localThumbnailFile.path}
                    <Button
                      size="sm"
                      className="text-right"
                      variant="danger"
                      disabled={progress === Progress.Attempting}
                      onClick={handleDelThumbnailFile}
                    >
                      削除
                    </Button>
                  </>
                )}
              </Form>
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <Form>
                <Form.Check
                  id="category-status"
                  type="switch"
                  checked={status == 1}
                  onChange={handleStatusChange}
                  label={status ? "有効" : "無効"}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/movie/`} className="btn-secondary w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
