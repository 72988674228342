import React from 'react'

function App() {
  return (
    <>
      <h1 className="h3">Page Not Found</h1>
    </>
  )
}

export default App;
