import React from "react"
import { Link } from "react-router-dom"
import { ICategory } from "../../lib/interface"

function App(props: any) {
  const topCategories = props.topCategories
  const count = 1
  return (
    <>
      {topCategories?.map((topCategory: ICategory, index: number) => {
        return (
          <>
            <span key={`category-${topCategory.id}`} className={`dep dep-2 me-3`}>
              {topCategory.id !== props.targetId && !props.searchIds?.includes(topCategory.id) && (
                <Link to={`/category/${topCategory.id}`}>{topCategory.name}</Link>
              )}
            </span>
          </>
        )
      })}
    </>
  )
}

export default App
