import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Modal } from "../common"
import { Admin } from "../../admin"
import { IAccount, IAccountAttributes, validAccount } from "../../lib/interface"
import * as api from "../../lib/api"
import * as iconv from "iconv-lite"
import { usePapaParse } from "react-papaparse"

function App() {
  const [adminLib, setAdminLib] = useState<Admin>()
  const [errorMessage, setErrorMessage] = useState("")
  const [addErrorInfo, setAErrorddInfo] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [importUsers, setImportUsers] = useState<IAccount[]>([])
  const [users, setUsers] = useState<IAccount[]>([])
  const [attempting, setAttempting] = useState(false)
  const [nextId, setNextId] = useState<string>("")

  const { readString } = usePapaParse()

  useEffect(() => {
    new Admin(setAdminLib)
  }, [])

  const mappingUsers = async (importUsers: IAccount[]) => {
    const users = await Promise.all(
      importUsers.map(async (user: IAccount, index: number) => {
        const cognitoJson =
          (await adminLib.listUsers({
            Filter: `email="${user.email}"`,
            Limit: 1,
          })) || []
        const flag = cognitoJson?.Users?.length > 0 ? true : false
        return { ...user, flag }
      })
    )
    setUsers(users)
  }

  useEffect(() => {
    mappingUsers(importUsers)
  }, [importUsers])

  //動画アップロード処理
  const onCsvDrop = async (acceptedFiles: any[]) => {
    setAttempting(true)
    if (acceptedFiles[0].type.includes("text/csv")) {
      const reader = new FileReader()
      reader.onload = () => {
        // CSVファイルのデータ（reader.result）を変換しShift_JISの文字化けに対応
        const csvOriginData = new Uint8Array(reader.result as ArrayBuffer)
        const csvConvertData = iconv.decode(csvOriginData as Buffer, "Shift_JIS")
        //CSV文字列をJSONに変換
        readString(csvConvertData, {
          worker: true,
          complete: (results) => {
            const importCsvUsers = results?.data
              ?.filter((dataColumn: string[], index: number) => {
                // ヘッダデータ以外と退会日時がない場合
                return index > 0 && dataColumn[11]?.length === 0
              })
              ?.map((dataColumn: string[], index: number) => {
                // ID	会員番号	氏名（漢字）	氏名（ｶﾅ）	電話番号	郵便番号	住所	メールアドレス	契約状況	契約日時	利用開始希望日	退会日時	解約日時	決済方法	カードブランド	決済方法登録用URL	コース	継続課金(月次)	継続課金(年次)	初回継続課金日	次回継続課金日	入会のきっかけ	パスワード	アンケート項目	メモ	規約同意

                return {
                  userNo: dataColumn[1],
                  name: dataColumn[2],
                  email: dataColumn[7],
                  tempPassword: dataColumn[22],
                }
              })
            setImportUsers(importCsvUsers)
          },
        })
      }
      //CSVファイルを読み込む
      reader.readAsArrayBuffer(acceptedFiles[0])
    } else {
      setErrorMessage("ファイル形式が不正です")
    }
    setAttempting(false)
  }

  const handleSave = async (props: IAccount) => {
    setAttempting(true)
    try {
      //Cognitoにメールアドレスの存在確認
      const cognitoJson = await adminLib.listUsers({
        Filter: `email="${props.email}"`,
        Limit: 1,
      })
      //Cognitoにメールアドレスがある場合
      if (cognitoJson?.Users?.length > 0) {
        let alreadyUserNo = ""
        const sub = cognitoJson?.Users[0].Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
        //RDSから情報取得
        const json = await api.getUserDb(sub)
        if (json?.data) {
          console.log(json.data)
          alreadyUserNo = json.data.userNo
          setAErrorddInfo(`[${props.email}]-[${alreadyUserNo}]`)
        }
        setErrorMessage(`そのメールアドレスはすでに登録されています。`)
      } else {
        //ない場合
        validAccount(props.userNo, props.email, props.name, props.tempPassword)
        //ユーザ作成
        const createParams = {
          Username: props.email,
          MessageAction: "SUPPRESS",
          UserAttributes: [
            {
              Name: "email_verified",
              Value: "true",
            },
            {
              Name: "email",
              Value: props.email,
            },
          ],
        }
        const passwordParams = {
          Username: props.email,
          Password: props.tempPassword,
          Permanent: true,
        }
        const createJson = await adminLib.createUser(createParams)
        console.log(createJson)
        const passwordJson = await adminLib.setUserPassword(passwordParams)
        console.log(passwordJson)
        //RDS用の処理
        if (createJson.User.Attributes.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value) {
          const cognitoId = createJson.User.Attributes.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
          try {
            const rdsUserJson = await api.createUserDb(cognitoId, props.userNo, props.name, "")
            if (rdsUserJson) {
              console.log(rdsUserJson)
            }
          } catch (err) {
            //RDSへの保存が失敗した場合には、Cognitoから削除する
            const params = {
              Username: props.email,
            }
            await adminLib?.deleteUser(params)
            throw new Error("登録に失敗しました")
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    setAttempting(false)
  }

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  return (
    <>
      <Modal show={modalShow} message={errorMessage} addinfo={addErrorInfo} onHide={handleCloseModal} />
      <h1 className="h4">ユーザCSVインポート</h1>
      <div className="mb-3">
        <Link to={`/user/`} className="btn-secondary btn-sm btn">
          一覧へ戻る
        </Link>
      </div>
      <Dropzone onDrop={onCsvDrop} accept={{ "text/csv": [] }}>
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone border mb-3 text-center p-3">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="mt-3 small">
                CSVファイルをドラッグアンドドロップ、
                <br />
                または選択してアップロードしてください。
                <br />
              </p>
            </div>
          </section>
        )}
      </Dropzone>
      <div className="table-scroll">
        <Table hover className="admin-table">
          <thead className="text-center bg-light">
            <tr>
              <th>会員番号</th>
              <th>名前</th>
              <th>Email</th>
              <th></th>
              <th>パスワード</th>
              <th>登録</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {users.length > 0 &&
              users?.map((user: IAccount, index: number) => {
                return (
                  <tr key={`user-${index}`}>
                    <td>{user.userNo}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.flag && "登録済み"}</td>
                    <td>********</td>
                    <td>
                      <Button
                        className="btn-block"
                        disabled={attempting}
                        onClick={() =>
                          handleSave({
                            userNo: user.userNo,
                            name: user.name,
                            email: user.email,
                            tempPassword: user.tempPassword,
                          })
                        }
                      >
                        登録
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default App
