import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Table from "react-bootstrap/Table"
import Image from "react-bootstrap/Image"
import * as api from "../../lib/api"
import { ICategory, IMovie } from "../../lib/interface"
import { textAddBr } from "../../lib/helper"
import ReactPlayer from "react-player"
import FormModal from "./_form_modal"

function App() {
  const [allCategories, setAllCategories] = useState<ICategory[] | null>(null)
  const [movie, setMovie] = useState<IMovie | null>(null)
  const params = useParams<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [formModalShow, setFormModalShow] = useState(false)
  //modalで表示するformのタイプを保持する
  const [formType, setFormType] = useState<string>("")

  const player = useRef<ReactPlayer>(null)

  useEffect(() => {
    const id = params.id
    getMovie(id)
    getCategories()
  }, [params.id])

  const getMovie = async (id: string) => {
    try {
      const json: any = await api.getMovieDb(id)
      console.log(json)
      if (json?.data) {
        setMovie(json.data)
      }
      setIsLoaded(false)
    } catch (err) {
      console.log(err.message || err)
    }
  }

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    console.log(json)
    setAllCategories(json?.data)
  }

  const handleModalShow = (type: string) => {
    setFormType(type)
    setFormModalShow(true)
  }

  const playMovie = () => {
    console.log("play!")
  }

  return (
    <React.Fragment>
      {isLoaded && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && movie && (
        <>
          <FormModal
            movie={movie}
            getMovie={getMovie}
            formType={formType}
            show={formModalShow}
            setFormModalShow={setFormModalShow}
            onHide={() => setFormModalShow(false)}
          />
          <h1 className="h4">動画詳細</h1>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/movie/">動画一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{movie?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <p>無効の動画のみ削除可能です。</p>
          <Table className="movie-table">
            <tbody>
              <tr>
                <th>ステータス</th>
                <td>
                  {movie.status ? <div className="text-info">有効</div> : <div className="text-danger">無効</div>}
                </td>
                <td>
                  {
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("status")
                      }}
                    >
                      変更
                    </Button>
                  }
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>{movie?.viewNumber}</td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("viewNumber")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>名前</th>
                <td>{movie?.name}</td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("name")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>サムネイル</th>
                <td className="thumb">
                  {!movie?.hlsFile && <p>変換中</p>}
                  {movie?.hlsFile && movie?.thumbnailFile && movie?.thumbnailNumber === 0 && (
                    <Image
                      className="img-thumbnail"
                      src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.thumbnailFile}`}
                      rounded
                    />
                  )}
                  {movie?.hlsFile && movie?.thumbnailNumber === 1 && (
                    <Image
                      className="img-thumbnail"
                      src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                        "movies",
                        "images"
                      )}_thumbnail.0000000.jpg`}
                      rounded
                    />
                  )}
                  {movie?.hlsFile && movie?.thumbnailNumber === 2 && (
                    <Image
                      className="img-thumbnail"
                      src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                        "movies",
                        "images"
                      )}_thumbnail.0000001.jpg`}
                      rounded
                    />
                  )}
                  {movie?.hlsFile && movie?.thumbnailNumber === 3 && (
                    <Image
                      className="img-thumbnail"
                      src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                        "movies",
                        "images"
                      )}_thumbnail.0000002.jpg`}
                      rounded
                    />
                  )}
                </td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("thumbnailFile")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>動画ファイル</th>
                <td className="movie">
                  {movie?.hlsFile && (
                    <>
                      <div className="player">
                        <ReactPlayer
                          ref={player}
                          url={`https://${process.env.REACT_APP_DOMAIN}/${movie.hlsFile}.m3u8`}
                          className="h-100"
                          light={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                            "movies",
                            "images"
                          )}_thumbnail.0000000.jpg`}
                          controls
                          playing={true}
                          playsinline={true}
                          progressInterval={1000}
                          onStart={() => playMovie()}
                        />
                      </div>
                      <div>再生時間：{movie.playTime}</div>
                    </>
                  )}
                  {!movie?.hlsFile && <>変換中</>}
                </td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("hlsFile")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>コメント</th>
                <td>{textAddBr(movie?.comment)}</td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("comment")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>所属</th>
                <td>
                  {
                    allCategories?.find((category: ICategory) => {
                      return category.id === movie.categoryId
                    })?.name
                  }
                </td>
                <td>
                  {movie?.hlsFile && (
                    <Button
                      size="sm"
                      className="text-right"
                      onClick={() => {
                        handleModalShow("category")
                      }}
                    >
                      変更
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <th>再生数</th>
                <td colSpan={2}>{movie?.playCount}</td>
              </tr>
              <tr>
                <th>更新日</th>
                <td colSpan={2}>{movie?.updateDate}</td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-6">
              <Button href={`/movie/`} className="btn-secondary btn-sm">
                一覧へ戻る
              </Button>
            </div>
            <div className="col-6 text-end">
              {movie.status === 0 && (
                <Button
                  onClick={() => {
                    handleModalShow("delete")
                  }}
                  className="btn-sm btn-block"
                  variant="danger"
                >
                  削除
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default App
