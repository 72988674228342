import React, { useCallback, useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill"
import { BsFillCaretUpFill } from "@react-icons/all-files/bs/BsFillCaretUpFill"
import { Link } from "react-router-dom"
import { Admin } from "../../admin"
import { IAccount, ISortType, IAccountAttributes } from "../../lib/interface"
import * as api from "../../lib/api"
import FormModal from "./_form_modal"

function App() {
  const [adminLib, setAdminLib] = useState<Admin>()
  const [user, setUser] = useState<IAccount>(null)
  const [users, setUsers] = useState<IAccount[]>([])
  const [searchUserNo, setSearchUserNo] = useState("")
  const [searchName, setSearchName] = useState("")
  const [attempting, setAttempting] = useState(false)
  const [sortType, setSortType] = useState<ISortType>()
  const [nextId, setNextId] = useState<string>("")
  const [formModalShow, setFormModalShow] = useState(false)
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    new Admin(setAdminLib)
  }, [])

  useEffect(() => {
    if (adminLib) {
      getUsers(true)
    }
  }, [adminLib, sortType])

  // ユーザは一度に10件取得を必須の仕様とする。Cognitoの秒間アクセスは30リクエストとなっている
  const getUsers = async (initialize?: boolean) => {
    try {
      //ソートと検索の初回時は、nextIdを使用せず、初期のリストとして取得しなおす
      if (initialize) {
        setNextId("")
        const json = await api.getUsersDb(searchUserNo, searchName, sortType?.type, sortType?.order)
        if (json?.data && json?.data.length > 0) {
          const mapUsers = (await mappingUsers(json?.data)) || []
          setUsers(mapUsers)
          setNextId(json?.data?.slice(-1)?.[0]?.nextId || "")
        }
        //初期化以外の場合はcategorysを追加していく
      } else {
        const json = await api.getUsersDb(searchUserNo, searchName, sortType?.type, sortType?.order, nextId)
        if (json?.data) {
          const mapUsers = (await mappingUsers(json?.data)) || []
          setUsers([...users, ...mapUsers])
          setNextId(json?.data?.slice(-1)?.[0]?.nextId || "")
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  // RDSのUser情報とCognitoのUser情報をマッピング RDSの取得Limitが10件に設定。Cognitoの秒間アクセスは10リクエストとなっている
  const mappingUsers = async (users: IAccount[]): Promise<IAccount[]> => {
    const mappingUsers = await Promise.all(
      users.map(async (user: IAccount, index: number) => {
        const cognitoJson =
          (await adminLib.listUsers({
            Filter: `sub="${user.cognitoId}"`,
            Limit: 1,
          })) || []
        let email = ""
        //もしCognitoの情報（email）が取得できないときには、認証情報がないデータとして削除を促す
        if (cognitoJson?.Users[0]) {
          email = cognitoJson.Users[0].Attributes?.filter((v: IAccountAttributes) => v.Name === "email")[0]?.Value
        }
        console.log({ ...user, ...cognitoJson.Users[0], email: email })
        return { ...user, ...cognitoJson.Users[0], email: email }
      })
    )
    return mappingUsers
  }

  const handleSearchNameChange = useCallback((e: any) => setSearchName(e.target.value), [])
  const handleSearchUserNoChange = useCallback((e: any) => setSearchUserNo(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  const handleEnable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      const enableJson = await adminLib?.enableUser(params)
      getUsers(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDisable = async (username: string) => {
    const params = {
      Username: username,
    }
    try {
      const disableJson = await adminLib?.disableUser(params)
      getUsers(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleOnlyDbDelete = async (username: string) => {
    try {
      const rdsUserJson = await api.delUserDb(username)
      getUsers(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleModalShow = (type: string, user: IAccount) => {
    setFormType(type)
    setUser(user)
    setFormModalShow(true)
  }

  return (
    <>
      <FormModal
        user={user}
        getUsers={getUsers}
        adminLib={adminLib}
        formType={formType}
        show={formModalShow}
        setFormModalShow={setFormModalShow}
        onHide={() => setFormModalShow(false)}
      />
      <h1 className="h4">ユーザ一覧</h1>
      <div className="mb-3">
        <Link to={`/user/create/`} className="btn-primary btn-sm btn">
          新規作成
        </Link>
      </div>
      <div className="mb-3">
        <Link to={`/user/csv/`} className="btn-primary btn-sm btn">
          CSVインポート
        </Link>
      </div>
      <Form className="search-area mb-5">
        <Row className="py-4">
          <Col sm={3}>
            <Form.Group>
              <Form.Control
                placeholder="会員番号"
                type="text"
                disabled={attempting}
                onChange={handleSearchUserNoChange}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group>
              <Form.Control placeholder="名前" type="text" disabled={attempting} onChange={handleSearchNameChange} />
            </Form.Group>
          </Col>
          <Col sm={3} className="d-flex align-items-center">
            <Button disabled={attempting} onClick={() => getUsers(true)} className="search-btn btn-block">
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="table-scroll">
        <Table hover className="admin-table">
          <thead className="text-center bg-light">
            <tr>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="user_no">
                  {sortType?.type === "user_no" && sortType?.order === "asc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                会員番号
              </th>
              <th>名前</th>
              <th>Email</th>
              <th>ライセンス</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="update_date">
                  {sortType?.type === "update_date" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                更新日
              </th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {users.length > 0 &&
              users?.map((user: IAccount, index: number) => {
                return (
                  <tr key={`user-${index}`}>
                    <td>{user.userNo}</td>
                    <td>{user.name}</td>
                    <td>
                      {!user.email && <span className="text-danger">ログイン不可。削除してください。</span>}
                      {user.email && (
                        <Link to={`/user/${user.cognitoId}`} className="btn-sm">
                          {user.email ? user.email : "ログイン不可。削除してください。"}
                        </Link>
                      )}
                    </td>

                    <td>
                      {user.licenseName}
                    </td>
                    <td>{user.updateDate}</td>
                    <td>
                      {!user.Username && (
                        <Button
                          onClick={() => handleOnlyDbDelete(user.cognitoId)}
                          className="btn-sm btn-block"
                          variant="danger"
                        >
                          削除
                        </Button>
                      )}
                      {user.Username && user.Enabled && (
                        <Button onClick={() => handleDisable(user.Username)} className="btn-sm  btn-block">
                          無効化
                        </Button>
                      )}
                      {user.Username && !user.Enabled && (
                        <Button onClick={() => handleEnable(user.Username)} className="btn-sm btn-success btn-block">
                          有効化
                        </Button>
                      )}
                      {user.Username && !user.Enabled && (
                        <Button
                          onClick={() => handleModalShow("delete", user)}
                          className="btn-sm btn-block"
                          variant="danger"
                        >
                          削除
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {nextId && (
          <Button onClick={() => getUsers(false)} className="btn-sm btn-block btn-primary">
            次へ
          </Button>
        )}
      </div>
    </>
  )
}

export default App
