import React, { useCallback, useEffect, useState } from "react"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { Link } from "react-router-dom"
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill"
import { BsFillCaretUpFill } from "@react-icons/all-files/bs/BsFillCaretUpFill"
import * as api from "../../lib/api"
import { ICategory, ISortType, IMovie } from "../../lib/interface"
import SelectTreeCategory from "../category/_select_tree_category"

function App() {
  const [allCategories, setAllCategories] = useState<ICategory[]>([])
  const [movies, setMovies] = useState<IMovie[]>([])
  const [searchText, setSearchText] = useState("")
  const [attempting] = useState(false)
  //並び順の初期値を与えておく 更新日のDESC
  const [sortType, setSortType] = useState<ISortType>({
    type: "create_date",
    order: "desc",
  })
  const [nextId, setNextId] = useState<string>("")
  const [parentId, setParentId] = useState("")

  useEffect(() => {
    getMovies(true)
  }, [sortType])

  useEffect(() => {
    getCategories()
  }, [movies])

  useEffect(() => {
    getMovies(true)
  }, [parentId])

  const getMovies = async (initialize?: boolean) => {
    let json
    //ソートと検索の時は、nextIdを使用せず、初期のリストとして取得しなおす
    if (initialize) {
      //一旦動画を空にする
      setMovies([])
      setNextId("")
      json = await api.getMoviesDb(searchText, parentId, sortType?.type, sortType?.order)
      console.log(json)
      if (json?.data.length) {
        setMovies(json?.data)
        setNextId(json?.data?.slice(-1)?.[0]?.nextId || "")
      }
      //最も最初に全てのカテゴリを取得する ツリー表示するため
      if (!movies && !allCategories) {
        setAllCategories(json?.data)
      }
      //初期化以外の場合はmoviesを追加していく
    } else {
      json = await api.getMoviesDb(searchText, "", sortType?.type, sortType?.order, nextId)
      console.log(json)
      if (json?.data) {
        if (movies) {
          setMovies([...movies, ...json?.data])
        } else {
          setMovies(json?.data.json)
        }
        setNextId(json?.data?.json?.slice(-1)?.[0]?.nextId || "")
      }
    }
  }

  const getCategories = async () => {
    const categoriesJson = await api.getCategoriesDb()
    setAllCategories(categoriesJson?.data)
  }

  const handleNameChange = useCallback((e: any) => setSearchText(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    //typeが切り替わるタイミングで"asc"にする
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  return (
    <>
      <h1 className="h4">動画一覧</h1>
      <div className="mb-3">
        <Link to={`/movie/create/`} className="btn-primary btn-sm btn">
          新規作成
        </Link>
      </div>
      <Form className="search-area mb-5">
        <div className="row">
          <div className="text-secondary m-2">カテゴリを選択して絞り込み</div>
          <Link to="#" className={"mb-3"} onClick={() => setParentId("")}>
            カテゴリ選択解除
          </Link>
          {allCategories.length > 0 && (
            <SelectTreeCategory
              allCategories={allCategories}
              topCategories={allCategories?.filter((category: ICategory, index: number) => {
                return !category.parentId
              })}
              targetIds={[parentId]}
              setSelectedId={setParentId}
            />
          )}
          <div className="col-9 py-4">
            <Form.Group>
              <Form.Control placeholder="名前" type="text" disabled={attempting} onChange={handleNameChange} />
            </Form.Group>
          </div>
          <div className="col-2 d-flex align-items-center">
            <Button disabled={attempting} onClick={() => getMovies(true)} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <div className="table-scroll">
        <Table hover className="admin-table text-sm">
          <thead className="bg-light text-center">
            <tr>
              <th></th>
              <th className="text-nowrap">
                <Link to="#" onClick={handleSortTypeChange} className="link" id="view_number">
                  {sortType?.type === "view_number" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                表示順
              </th>
              <th>サムネイル画像</th>
              <th>名前</th>
              <th>所属カテゴリ</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="update_date">
                  {sortType?.type === "update_date" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                更新日
              </th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="play_count">
                  {sortType?.type === "play_count" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                再生回数
              </th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="status">
                  {sortType?.type === "status" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                ステータス
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {movies?.length > 0 &&
              movies?.map((movie: IMovie, index: number) => {
                return (
                  <tr key={`movie-${index}`}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{movie.viewNumber}</td>
                    <td className="w-25">
                      {!movie?.hlsFile && <div className="alert alert-info">変換中</div>}
                      {movie?.hlsFile && movie?.thumbnailFile && movie?.thumbnailNumber === 0 && (
                        <Image
                          className="img-thumbnail w-50"
                          src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.thumbnailFile}`}
                          rounded
                        />
                      )}
                      {movie?.hlsFile && movie?.thumbnailNumber === 1 && (
                        <Image
                          className="img-thumbnail w-50"
                          src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                            "movies",
                            "images"
                          )}_thumbnail.0000000.jpg`}
                          rounded
                        />
                      )}
                      {movie?.hlsFile && movie?.thumbnailNumber === 2 && (
                        <Image
                          className="img-thumbnail w-50"
                          src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                            "movies",
                            "images"
                          )}_thumbnail.0000001.jpg`}
                          rounded
                        />
                      )}
                      {movie?.hlsFile && movie?.thumbnailNumber === 3 && (
                        <Image
                          className="img-thumbnail w-50"
                          src={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                            "movies",
                            "images"
                          )}_thumbnail.0000002.jpg`}
                          rounded
                        />
                      )}
                    </td>
                    <td className="align-middle">
                      <Link to={`/movie/${movie.id}/`} className="">
                        {movie.name}
                      </Link>
                    </td>
                    <td className="align-middle">
                      {
                        allCategories?.find((category: ICategory) => {
                          return category.id === movie.categoryId
                        })?.name
                      }
                    </td>
                    <td className="align-middle">{movie.updateDate}</td>
                    <td className="align-middle">{movie.playCount}</td>
                    <td className="align-middle">
                      {movie.status ? <div className="text-info">有効</div> : <div className="text-danger">無効</div>}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        {nextId && (
          <Button onClick={() => getMovies(false)} className="btn-sm btn-block btn-primary">
            次へ
          </Button>
        )}
      </div>
    </>
  )
}

export default App
