import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { AuthContext } from "../../lib/auth"
import { Modal } from "../common"

enum Progress {
  SignIn,
  Confirm,
}

function App() {
  const [progress, setProgress] = useState(Progress.SignIn)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [attempting, setAttempting] = useState(false)

  const [username, setUsername] = useState("")
  const [confirmCode, setConfirmCode] = useState("")
  const [user, setUser] = useState<any>(null)

  const history = useNavigate()

  const auth = useContext(AuthContext)

  // handler
  const handleUsernameChange = useCallback((e: any) => setUsername(e.target.value), [])
  const handleConfirmCodeChange = useCallback((e: any) => setConfirmCode(e.target.value), [])

  const handleSignIn = useCallback(() => {
    setAttempting(true)

    auth
      .signIn(username)
      .then((data) => {
        setAttempting(false)
        setProgress(Progress.Confirm)
        setUser(data)
      })
      .catch((err) => {
        setAttempting(false)
        setErrorMessage(err.message)
      })
  }, [auth, username])

  const handleConfirm = useCallback(() => {
    setAttempting(true)
    auth
      .answerCustomChallenge(user, confirmCode)
      .then((user) => {
        setAttempting(false)
        console.log(user)
      })
      .catch((err) => {
        setAttempting(false)
        setErrorMessage(err.message)
      })
  }, [auth, user, confirmCode])

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  useEffect(() => {
    if (auth.currentUser) {
      window.location.href = "/"
    }
  }, [auth.currentUser, history])

  return (
    <section>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      {progress === Progress.SignIn &&
        showSignIn({
          handleUsernameChange,
          handleSignIn,
          attempting,
          auth,
        })}
      {progress === Progress.Confirm &&
        showConfirm({
          handleConfirmCodeChange,
          handleConfirm,
          attempting,
        })}
    </section>
  )
}

const showSignIn = (props: any) => {
  return (
    <Card>
      <Card.Header>
        <img src="/img/logo.png" />
        <br />
        <h5 className="text-white my-2">- 管理者 -</h5>
      </Card.Header>
      <Card.Body className="m-3">
        <Form>
          <Form.Group>
            <Form.Label className="fw-bold mb-3">Email認証</Form.Label>
            <Form.Control
              type="email"
              name="username"
              placeholder="E-mail"
              autoComplete="username"
              disabled={props.attempting}
              onChange={props.handleUsernameChange}
              className="mb-1"
            />
            <hr className="my-4" />
            <div className="signin-btn-area">
              <Button
                className="btn-primary btn-block w-100 mb-4"
                disabled={props.attempting}
                onClick={props.handleSignIn}
              >
                ログイン
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}

const showConfirm = (props: any) => {
  return (
    <Card>
      <Card.Header>
        <img src="/img/logo.png" />
        <br />
        <h5 className="text-white my-2">- 管理者 -</h5>
      </Card.Header>
      <Card.Body className="m-3">
        <Form>
          <Form.Group controlId="formBasicConfirmCode">
            <Form.Label className="fw-bold mb-3">認証コード</Form.Label>
            <Form.Control
              type="number"
              name="confirm-code"
              placeholder="123456"
              disabled={props.attempting}
              onChange={props.handleConfirmCodeChange}
            />
          </Form.Group>
          <hr className="my-4" />
          <div className="signin-btn-area">
            <Button className=" btn-block w-100" disabled={props.attempting} onClick={props.handleConfirm}>
              認証
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default App
