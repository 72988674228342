import React from "react"
import { Routes, BrowserRouter as Router, Route } from "react-router-dom"

import MyRoute from "./lib/custom-route"

import UserIndex from "./pages/user/index"
import UserCreate from "./pages/user/create"
import UserDetail from "./pages/user/detail"
import UserCsv from "./pages/user/csv"
import LicenseIndex from "./pages/license/index"
import LicenseCreate from "./pages/license/create"
import LicenseDetail from "./pages/license/detail"
import CategoryIndex from "./pages/category/index"
import CategoryCreate from "./pages/category/create"
import CategoryDetail from "./pages/category/detail"
import MovieIndex from "./pages/movie/index"
import MovieCreate from "./pages/movie/create"
import MovieDetail from "./pages/movie/detail"
import NoticeIndex from "./pages/notice/index"
import NoticeCreate from "./pages/notice/create"
import NoticeDetail from "./pages/notice/detail"
import AdminIndex from "./pages/admin/index"
import AdminMypage from "./pages/admin/detail"
import AdminCreate from "./pages/admin/create"
import SignIn from "./pages/auth/signin"
import SignOut from "./pages/auth/signout"
import PageNotFound from "./pages/page-not-found"

function App({ children, ...rest }: any) {
  return (
    <Router>
      <Routes>
        <Route path="auth">
          <Route path="signin" element={<MyRoute Component={<SignIn />} wrap contents id="auth-signin" />} />
          <Route path="signout" element={<MyRoute Component={<SignOut />} wrap auth contents id="auth-signout" />} />
        </Route>
        <Route path="/" element={<MyRoute Component={<AdminMypage />} wrap auth contents id="admin-mypage" />} />
        <Route path="admin">
          <Route path="" element={<MyRoute Component={<AdminIndex />} wrap auth contents id="admin-index" />} />
          <Route path="create" element={<MyRoute Component={<AdminCreate />} wrap auth contents id="admin-create" />} />
        </Route>
        <Route path="user">
          <Route path="" element={<MyRoute Component={<UserIndex />} wrap auth contents id="user-index" />} />
          <Route path="create" element={<MyRoute Component={<UserCreate />} wrap auth contents id="user-create" />} />
          <Route path=":id" element={<MyRoute Component={<UserDetail />} wrap auth contents id="user-detail" />} />
          <Route path="csv" element={<MyRoute Component={<UserCsv />} wrap auth contents id="user-csv" />} />
        </Route>
        <Route path="license">
          <Route path="" element={<MyRoute Component={<LicenseIndex />} wrap auth contents id="license-index" />} />
          <Route
            path="create"
            element={<MyRoute Component={<LicenseCreate />} wrap auth contents id="license-create" />}
          />
          <Route
            path=":id"
            element={<MyRoute Component={<LicenseDetail />} wrap auth contents id="license-detail" />}
          />
        </Route>
        <Route path="category">
          <Route path="" element={<MyRoute Component={<CategoryIndex />} wrap auth contents id="category-index" />} />
          <Route
            path="create"
            element={<MyRoute Component={<CategoryCreate />} wrap auth contents id="category-create" />}
          />
          <Route
            path=":id"
            element={<MyRoute Component={<CategoryDetail />} wrap auth contents id="category-detail" />}
          />
        </Route>
        <Route path="movie">
          <Route path="" element={<MyRoute Component={<MovieIndex />} wrap auth contents id="movie-index" />} />
          <Route path="create" element={<MyRoute Component={<MovieCreate />} wrap auth contents id="movie-create" />} />
          <Route path=":id" element={<MyRoute Component={<MovieDetail />} wrap auth contents id="movie-detail" />} />
        </Route>
        <Route path="notice">
          <Route path="" element={<MyRoute Component={<NoticeIndex />} wrap auth contents id="notice-index" />} />
          <Route
            path="create"
            element={<MyRoute Component={<NoticeCreate />} wrap auth contents id="notice-create" />}
          />
          <Route path=":id" element={<MyRoute Component={<NoticeDetail />} wrap auth contents id="notice-detail" />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  )
}

export default App
