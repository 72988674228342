import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import { ILicense } from "../../lib/interface"
import * as api from "../../lib/api"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

enum Progress {
  Preparing,
  Attempting,
}

function App(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [progress, setProgress] = useState(Progress.Preparing)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter" className="p-2 border-bottom">
        {props.formType === "user_no" && "会員番号"}
        {props.formType === "email" && "Email"}
        {props.formType === "name" && "名前"}
        {props.formType === "license" && "ライセンス"}
        {props.formType === "delete" && "ご確認ください"}
      </Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        {props.formType === "userNo" && (
          <ShowUserNoForm
            adminLib={props.adminLib}
            user={props.user}
            show={props.show}
            getUser={props.getUser}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "email" && (
          <ShowEmailForm
            adminLib={props.adminLib}
            user={props.user}
            show={props.show}
            getUser={props.getUser}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "name" && (
          <ShowNameForm
            user={props.user}
            show={props.show}
            getUser={props.getUser}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
        {props.formType === "license" && (
          <ShowLicenseForm
            user={props.user}
            show={props.show}
            getUser={props.getUser}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}

        {props.formType === "delete" && (
          <ShowDeleteForm
            user={props.user}
            show={props.show}
            getUser={props.getUser}
            getUsers={props.getUsers}
            adminLib={props.adminLib}
            setFormModalShow={props.setFormModalShow}
            setProgress={setProgress}
            setErrorMessage={setErrorMessage}
            progress={progress}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const ShowUserNoForm = (props: any) => {
  const [userNo, setUserNo] = useState("")
  const handleUserNoChange = useCallback((e: any) => setUserNo(e.target.value), [])

  useEffect(() => {
    setUserNo(props.user?.userNo || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleUserNoUpdate = async (e: any) => {
    try {
      userNoValid(userNo)
      const updateUser = { ...props.user, userNo: userNo }
      const rdsUserJson = await api.updateUserDb(props.user.cognitoId, updateUser)
      console.log(rdsUserJson)
      props.getUser()
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const userNoValid = (name: string) => {
    if (!userNo) {
      throw new Error("会員番号は必須です")
    }
    if (userNo?.length > 100) {
      throw new Error("会員番号は100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>名前</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="userNo"
          value={userNo}
          placeholder="会員番号"
          onChange={handleUserNoChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleUserNoUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowEmailForm = (props: any) => {
  const [email, setEmail] = useState("")
  const handleEmailChange = useCallback((e: any) => setEmail(e.target.value), [])

  useEffect(() => {
    setEmail(props.user.email)
    props.setErrorMessage("")
  }, [props.show])

  const handleEmailUpdate = async (e: any) => {
    props.setProgress(Progress.Attempting)
    emailValid(email)
    if (props.adminLib && props.user) {
      try {
        const params = {
          //初回登録時は同じだがUsernameとemailは別のものになる可能性あり
          Username: props.user.Username,
          UserAttributes: [
            {
              Name: "email",
              Value: email,
            },
            {
              Name: "email_verified",
              Value: "true",
            },
          ],
        }
        await props.adminLib.updateUserAttributes(params)
        props.getUser()
        props.setFormModalShow(false)
      } catch (err) {
        props.setErrorMessage(err)
      } finally {
        props.setProgress(Progress.Preparing)
      }
    }
  }

  const emailValid = (email: string) => {
    if (!email) {
      throw new Error("Emailは必須です")
    }
    if (email?.length > 100) {
      throw new Error("Emailは100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>Email</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="email"
          value={email}
          placeholder="Email"
          onChange={handleEmailChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleEmailUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowNameForm = (props: any) => {
  const [name, setName] = useState("")
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])

  useEffect(() => {
    setName(props.user?.name || "")
    props.setErrorMessage("")
  }, [props.show])

  const handleNameUpdate = async (e: any) => {
    try {
      nameValid(name)
      const cognitoId = props.user.cognitoId
      const updateUserDetail = { ...props.user, name: name }
      const rdsUserJson = await api.updateUserDb(cognitoId, updateUserDetail)
      console.log(rdsUserJson)
      props.getUser()
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const nameValid = (name: string) => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
  }

  return (
    <Form>
      <Form.Label>名前</Form.Label>
      <>
        <Form.Control
          size="sm"
          type="text"
          name="name"
          value={name}
          placeholder="名前"
          onChange={handleNameChange}
          disabled={props.progress === Progress.Attempting}
        />
        <Button
          size="sm"
          className="text-right"
          onClick={handleNameUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowLicenseForm = (props: any) => {
  const [allLicenses, setAllLicenses] = useState<ILicense[]>(null)
  const [licenseId, setLicenseId] = useState("")
  const handleLicenseIdChange = useCallback((e: any) => setLicenseId(e.target.value), [])

  useEffect(() => {
    //選択肢用のライセンス一覧取得
    getLicenses()
    setLicenseId(props.user?.licenseId || "")
    props.setErrorMessage("")
  }, [props.show])

  const getLicenses = async () => {
    try {
      const json = await api.getLicensesDb()
      if (json?.data) {
        setAllLicenses(json?.data)
      }
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleLicenseIdUpdate = async (e: any) => {
    try {
      licenseValid(licenseId)
      const cognitoId = props.user.cognitoId
      const updateUserDetail = { ...props.user, licenseId: licenseId }
      const rdsUserJson = await api.updateUserDb(cognitoId, updateUserDetail)
      console.log(rdsUserJson)
      props.getUser()
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const licenseValid = (name: string) => {
    if (licenseId) {
      const allLicenseIds = allLicenses.map((license: ILicense) => {
        return license.id
      })
      if (!allLicenseIds.includes(licenseId)) {
        throw new Error("ライセンスが不正です")
      }
    }
  }

  return (
    <Form>
      <Form.Label>ライセンス</Form.Label>
      <>
        <Form.Control as="select" size="sm" value={licenseId || ""} onChange={handleLicenseIdChange}>
          <option value="">ライセンスなし</option>
          {allLicenses?.map((license: ILicense, index: number) => {
            return (
              <option value={license.id} key={`license-${index}`}>
                {license.name}
              </option>
            )
          })}
        </Form.Control>
        <Button
          size="sm"
          className="text-right"
          onClick={handleLicenseIdUpdate}
          disabled={props.progress === Progress.Attempting}
        >
          変更
        </Button>
      </>
    </Form>
  )
}

const ShowDeleteForm = (props: any) => {
  const handleDelete = async () => {
    props.setProgress(Progress.Attempting)
    console.log(props.user)
    try {
      const cognitoId = props.user.cognitoId
      //ここでCognitoからの削除処理を行う
      const params = {
        Username: props.user.Username,
      }
      const json = await props.adminLib?.deleteUser(params)
      if (json) {
        const rdsUserJson = await api.delUserDb(cognitoId)
      }
      props.getUsers(true)
      props.setFormModalShow(false)
    } catch (err) {
      props.setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      props.setProgress(Progress.Preparing)
    }
  }

  const handleCancel = async () => {
    props.setFormModalShow(false)
  }

  return (
    <Form>
      <Form.Label className="fw-bold text-danger">このユーザーを完全に削除しますか？</Form.Label>
      <hr />
      <Row className="text-center">
        <Col>
          <Button
            size="sm"
            className="text-right btn-secondary"
            onClick={handleCancel}
            disabled={props.progress === Progress.Attempting}
          >
            キャンセル
          </Button>
        </Col>
        <Col>
          <Button
            variant="danger"
            size="sm"
            className="text-end"
            onClick={handleDelete}
            disabled={props.progress === Progress.Attempting}
          >
            削除する
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default App
