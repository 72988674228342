import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import { Modal } from "../common"
import * as api from "../../lib/api"
import { IAccountAttributes, ILicense, validAccount } from "../../lib/interface"
import { Admin } from "../../admin"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [adminLib, setAdminLib] = useState<Admin>()
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [userNo, setUserNo] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [licenseId, setLicenseId] = useState("")
  //選択肢用のライセンス格納用
  const [allLicenses, setAllLicenses] = useState<ILicense[]>(null)

  const handleUserNoChange = useCallback((e: any) => setUserNo(e.target.value), [])
  const handleEmailChange = useCallback((e: any) => setEmail(e.target.value), [])
  const handlePasswordChange = useCallback((e: any) => setPassword(e.target.value), [])
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleLicenseIdChange = useCallback((e: any) => setLicenseId(e.target.value), [])

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    new Admin(setAdminLib)
    getLicenses()
  }, [])

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const getLicenses = async () => {
    try {
      const json = await api.getLicensesDb()
      if (json?.data) {
        setAllLicenses(json?.data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const handleSave = async (e: any) => {
    setProgress(Progress.Attempting)
    try {
      validAccount(userNo, email, name, password, licenseId, allLicenses)
      //Cognito用 登録->パスワード変更(登録時に初回パスワード変更必須)
      if (adminLib && email) {
        const createParams = {
          Username: email,
          MessageAction: "SUPPRESS",
          UserAttributes: [
            {
              Name: "email_verified",
              Value: "true",
            },
            {
              Name: "email",
              Value: email,
            },
          ],
        }
        const passwordParams = {
          Username: email,
          Password: password,
          Permanent: true,
        }
        const createJson = await adminLib.createUser(createParams)
        console.log(createJson)
        const passwordJson = await adminLib.setUserPassword(passwordParams)
        console.log(passwordJson)
        //RDS用の処理
        if (createJson.User.Attributes.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value) {
          const cognitoId = createJson.User.Attributes.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
          try {
            const rdsUserJson = await api.createUserDb(cognitoId, userNo, name, licenseId)
            if (rdsUserJson) {
              console.log(rdsUserJson)
              history(`/user/`)
            }
          } catch (err) {
            //RDSへの保存が失敗した場合には、Cognitoから削除する
            const params = {
              Username: email,
            }
            await adminLib?.deleteUser(params)
            throw new Error("登録に失敗しました")
          }
        }
      }
    } catch (err) {
      setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">ユーザ新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/user/">ユーザ一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ユーザ作成</Breadcrumb.Item>
      </Breadcrumb>
      <Table className="user-table">
        <tbody>
          <tr>
            <th className="w-25">会員番号</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="userNo"
                  value={userNo}
                  placeholder="会員番号"
                  disabled={progress === Progress.Attempting}
                  onChange={handleUserNoChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th className="w-25">Eメール</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  value={email}
                  placeholder="Email"
                  disabled={progress === Progress.Attempting}
                  onChange={handleEmailChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>パスワード</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="password"
                  value={password}
                  placeholder="パスワード"
                  disabled={progress === Progress.Attempting}
                  onChange={handlePasswordChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>名前</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="名前"
                  disabled={progress === Progress.Attempting}
                  onChange={handleNameChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>ライセンス</th>
            <td>
              <Form>
                <Form.Control as="select" size="sm" value={licenseId || ""} onChange={handleLicenseIdChange}>
                  <option value="">選択してください</option>
                  {allLicenses?.map((license: ILicense, index: number) => {
                    return (
                      <option value={license.id} key={`license-${index}`}>
                        {license.name}
                      </option>
                    )
                  })}
                </Form.Control>
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/user/`} className="btn-secondary  w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
