import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function App(props: any) {
  const { t } = useTranslation()

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title || "エラーです"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fw-bold">{t(props.message)}</p>
        {props.addinfo && <p className="fw-bold">{props.addinfo}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default App
