import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../lib/auth"

function App() {
  const [currentUser, setCurrentUser] = useState<any>(null)
  const auth = useContext(AuthContext)

  useEffect(() => {
    setCurrentUser(auth.currentUser)
  }, [auth])

  return (
    <React.Fragment>
      {showAdmin({
        auth,
        currentUser,
      })}
    </React.Fragment>
  )
}

const showAdmin = (props: any) => {
  return (
    <>
      <h1 className="h4">管理者情報マイページ</h1>
      ログインID（Email）：{props.currentUser?.attributes?.email}
    </>
  )
}

export default App
