import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Table from "react-bootstrap/Table"
import * as api from "../../lib/api"
import { ICategory, IMovie } from "../../lib/interface"
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight"
import FormModal from "./_form_modal"
import LinkTreeCategory from "./_link_tree_category"
import ShowDetailChildren from "./_show_detail_children"

function App() {
  const [allCategories, setAllCategories] = useState<ICategory[] | null>(null)
  const [parentCategoryArray, setParentCategoryArray] = useState<ICategory[] | null>(null)

  const [category, setCategory] = useState<ICategory>(null)
  const params = useParams<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [formModalShow, setFormModalShow] = useState(false)
  //modalで表示するformのタイプを保持する
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    const id = params.id
    getCategory(id)
  }, [params.id])

  useEffect(() => {
    if (category) {
      getCategories()
    }
  }, [category])

  useEffect(() => {
    if (category && allCategories) {
      let categoryArray: ICategory[] = []
      //親カテゴリ格納
      categoryArray = [category]
      categoryArray = setParentCategories(allCategories, category.parentId, categoryArray)
      setParentCategoryArray(categoryArray)
    }
  }, [allCategories])

  const setParentCategories = (allCategories: ICategory[], parentId: string, categoryArray: ICategory[]) => {
    if (category.parentId) {
      const parentCategory = allCategories?.find((parentCategory: ICategory, index: number) => {
        return parentCategory.id === parentId
      })
      if (parentCategory) {
        categoryArray = [parentCategory, ...categoryArray]
        categoryArray = setParentCategories(allCategories, parentCategory.parentId, categoryArray)
      }
    }
    return categoryArray
  }

  const getCategory = async (id: string) => {
    try {
      const json: any = await api.getCategoryDb(id)
      console.log(json)
      if (json?.data) {
        setCategory(json.data)
      }
      setIsLoaded(false)
    } catch (err) {
      console.log(err.message || err)
    }
  }

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    setAllCategories(json?.data)
  }

  const handleModalShow = (type: string) => {
    setFormType(type)
    setFormModalShow(true)
  }

  return (
    <React.Fragment>
      {isLoaded && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && category && (
        <>
          <FormModal
            category={category}
            getCategory={getCategory}
            formType={formType}
            show={formModalShow}
            setFormModalShow={setFormModalShow}
            onHide={() => setFormModalShow(false)}
          />
          <h1 className="h4">カテゴリ詳細</h1>
          <div className="m-3">
            <LinkTreeCategory
              linkMode={true}
              allCategories={allCategories}
              topCategories={allCategories?.filter((category: ICategory, index: number) => {
                return !category.parentId
              })}
              targetIds={[category?.id]}
            />
          </div>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/category/">カテゴリ一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{category?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="alert alert-danger w-75 p-2">
            無効のカテゴリ且つ下位カテゴリや動画がないカテゴリのみ削除可能です。
          </div>
          <Table className="user-table border-top">
            <tbody>
              <tr>
                <th>名前</th>
                <td className="category-dep">
                  {parentCategoryArray?.map((cat: ICategory, index: number) => {
                    if (cat.name === category.name) {
                      return (
                        <>
                          <span key={`category-${index}`}>{cat?.name}</span>
                        </>
                      )
                    }
                  })}
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("name")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>

              <tr>
                <th>ステータス</th>
                <td>
                  {category.status === 0 && <div className="text-danger">非公開</div>}
                  {category.status === 1 && <div className="text-info">ライセンスユーザ公開</div>}
                  {category.status === 2 && <div className="text-warning">ゲスト公開</div>}
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("status")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>{category?.viewNumber}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("viewNumber")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>更新日</th>
                <td colSpan={2}>{category?.updateDate}</td>
              </tr>
              <tr>
                <th>所属</th>
                <td className="category-dep">
                  {parentCategoryArray?.map((cat: ICategory, index: number) => {
                    if (index === 0) {
                      return (
                        <span className={`dep-${index} dep`} key={`category-${index}`}>
                          {cat.name}
                        </span>
                      )
                    } else if (cat.name === category.name) {
                      return (
                        <>
                          {console.log(cat)}
                          <BsArrowRight size={20} className="mx-2" />
                          <span className={`dep-${index} dep cat`} key={`category-${index}`}>
                            {cat.name}
                          </span>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <BsArrowRight size={20} className="mx-2" />
                          <span className={`dep-${index} dep`} key={`category-${index}`}>
                            {cat.name}
                          </span>
                        </>
                      )
                    }
                  })}
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("parent")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>下位カテゴリ</th>
                <td className="category-dep">
                  {
                    <>
                      {allCategories?.filter((topCategory: ICategory) => {
                        return topCategory.parentId === category.id
                      }).length === 0 && <span>-</span>}

                      <ShowDetailChildren
                        detail={true}
                        allCategories={allCategories}
                        topCategories={allCategories?.filter((topCategory: ICategory) => {
                          return topCategory.parentId === category.id
                        })}
                        targetId={""}
                        searchIds={[]}
                      />
                    </>
                  }
                </td>
              </tr>
              <tr>
                <th>所属動画</th>
                <td colSpan={2}>
                  <div>
                    {category?.movies?.length === 0 && category?.movies && <span>-</span>}
                    {category?.movies?.length > 0 &&
                      category?.movies?.map((movie: IMovie, index: number) => {
                        return (
                          <div key={`movie-${movie.id}`}>
                            <Link to={`/movie/${movie.id}/`}>{movie.name}</Link>
                            {movie.status ? (
                              <div className="text-info d-inline"> [有効]</div>
                            ) : (
                              <div className="text-danger d-inline"> [無効]</div>
                            )}
                          </div>
                        )
                      })}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-6 text-start">
              <Button href={`/category/`} className="btn-sm">
                一覧へ戻る
              </Button>
            </div>
            <div className="col-6 text-end">
              {category.status === 0 &&
                !allCategories?.find((childCategory: ICategory) => {
                  return childCategory.parentId === category.id
                }) && (
                  <Button
                    variant="danger"
                    onClick={() => {
                      handleModalShow("delete")
                    }}
                    className="btn-sm"
                  >
                    削除
                  </Button>
                )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default App
