import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { Modal } from "../common"
import * as api from "../../lib/api"
import { ICategory } from "../../lib/interface"
import SelectTreeCategory from "./_select_tree_category"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const [viewNumber, setViewNumber] = useState<number>()
  const [status, setStatus] = useState<number>(0)
  const [allCategories, setAllCategories] = useState<ICategory[]>([])
  const [parentId, setParentId] = useState<string>("")
  const [depth, setDepth] = useState<number>(0)

  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleViewNumberChange = useCallback((e: any) => setViewNumber(+e.target.value), [])
  const handleStatusChange = (e: any) => {
    setStatus(+e.target.value)
  }

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    getCategories()
    setErrorMessage("")
  }, [])

  const getCategories = async () => {
    const json = await api.getCategoriesDb()
    setAllCategories(json?.data)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async (e: any) => {
    setProgress(Progress.Preparing)
    try {
      categoryValid()
      const json = await api.createCategoryDb(name, viewNumber, status, parentId)
      console.log(json)
      history(`/category/`)
    } catch (err) {
      setErrorMessage(err.message || err)
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const categoryValid = () => {
    if (!name) {
      throw new Error("名前は必須です")
    }
    if (name?.length > 100) {
      throw new Error("名前は100文字以内で入力してください")
    }
    if (!viewNumber && viewNumber !== 0) {
      throw new Error("表示順は必須です")
    }
    if (viewNumber < 1 || viewNumber > 10000) {
      throw new Error("表示順は1~10000までとなります")
    }
    if (
      parentId &&
      !allCategories
        ?.map((category: ICategory) => {
          return category.id
        })
        ?.includes(parentId)
    ) {
      throw new Error("所属が不正です。")
    }
    if (depth > 5) {
      throw new Error("所属の階層は5階層までです。")
    }
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">カテゴリ新規作成</h1>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/category/">カテゴリ一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>カテゴリ作成</Breadcrumb.Item>
      </Breadcrumb>
      <Table className="user-table">
        <tbody>
          <tr>
            <th>名前</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="名前"
                  disabled={progress === Progress.Attempting}
                  onChange={handleNameChange}
                  maxLength={100}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>表示順</th>
            <td>
              <Form>
                <Form.Control
                  size="sm"
                  type="number"
                  name="viewNumber"
                  value={`${viewNumber || 0}`}
                  placeholder="表示順数値"
                  disabled={progress === Progress.Attempting}
                  onChange={handleViewNumberChange}
                  maxLength={20}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              ゲスト公開にすると未ログインユーザにも公開されます
              <Form>
                <Form.Check
                  id="category-status-0"
                  name="category-status-0"
                  label="非公開"
                  type="radio"
                  value={0}
                  checked={status === 0}
                  onChange={handleStatusChange}
                />
                <Form.Check
                  id="category-status-1"
                  name="category-status-1"
                  label="ライセンスユーザ公開"
                  type="radio"
                  value={1}
                  checked={status === 1}
                  onChange={handleStatusChange}
                />
                <Form.Check
                  id="category-status-2"
                  name="category-status-2"
                  label="ゲスト公開"
                  type="radio"
                  value={2}
                  checked={status === 2}
                  onChange={handleStatusChange}
                />
              </Form>
            </td>
          </tr>
          <tr>
            <th>所属</th>
            <td>
              <Form>
                <div className="m-3">
                  <div className="m-2 category-tree">
                    <Link to="#" onClick={() => setParentId("")}>
                      {parentId === "" && <span className={`dep targetCategory`}>トップカテゴリ</span>}
                      {parentId != "" && <span className={`dep`}>トップカテゴリ</span>}
                    </Link>
                  </div>
                  <SelectTreeCategory
                    allCategories={allCategories}
                    topCategories={allCategories?.filter((category: ICategory, index: number) => {
                      return !category.parentId
                    })}
                    targetIds={[parentId]}
                    setSelectedId={setParentId}
                    setDepth={setDepth}
                  />
                </div>
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row mt-5 text-center">
        <div className="col-6">
          <Button href={`/category/`} className="btn-secondary  w-75">
            一覧へ戻る
          </Button>
        </div>
        <div className="col-6">
          <Button className="w-75" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
