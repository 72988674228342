import React, { useCallback, useEffect, useState } from "react"
import { Form, Button, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import * as api from "../../lib/api"
import { INotice, ISortType } from "../../lib/interface"
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill"
import { BsFillCaretUpFill } from "@react-icons/all-files/bs/BsFillCaretUpFill"

function App() {
  const [notices, setNotices] = useState<INotice[] | null>(null)
  const [searchName, setSearchName] = useState("")
  const [attempting] = useState(false)
  const [sortType, setSortType] = useState<ISortType>(null)

  useEffect(() => {
    getNotices()
  }, [sortType])

  const getNotices = async () => {
    const json = await api.getNoticesDb(searchName, sortType?.type, sortType?.order)
    console.log(json)
    setNotices(json?.data)
  }

  const handleSearchNameChange = useCallback((e: any) => setSearchName(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    //typeが切り替わるタイミングで"asc"にする
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  return (
    <>
      <h1 className="h4">お知らせ一覧</h1>
      <div className="mb-3">
        <Link to={`/notice/create/`} className="btn-primary btn-sm btn">
          新規作成
        </Link>
      </div>
      <Form className="search-area mb-5">
        <div className="row">
          <div className="col-9 py-4">
            <Form.Group>
              <Form.Control placeholder="名前" type="text" disabled={attempting} onChange={handleSearchNameChange} />
            </Form.Group>
          </div>
          <div className="col-2 d-flex align-items-center">
            <Button disabled={attempting} onClick={() => getNotices()} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <div className="table-scroll">
        <Table hover className="admin-table">
          <thead className="text-center">
            <tr>
              <th></th>
              <th>タイトル</th>
              <th>URL</th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="date">
                  {sortType?.type === "date" && <div>active</div>}
                  {sortType?.type === "date" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                日付
              </th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="update_date">
                  {sortType?.type === "update_date" && <div>active</div>}
                  {sortType?.type === "update_date" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                更新日
              </th>
              <th>
                <Link to="#" onClick={handleSortTypeChange} className="link" id="status">
                  {sortType?.type === "status" && <div>active</div>}
                  {sortType?.type === "status" && sortType?.order === "desc" ? (
                    <BsFillCaretUpFill />
                  ) : (
                    <BsFillCaretDownFill />
                  )}
                </Link>
                ステータス
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {notices?.length > 0 &&
              notices?.map((notice: INotice, index: number) => {
                return (
                  <tr key={notice.id}>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={`/notice/${notice.id}/`} className="">
                        {notice.title}
                      </Link>
                    </td>
                    <td>{notice.url}</td>
                    <td>{notice.date}</td>
                    <td>{notice.updateDate}</td>
                    <td>
                      {notice.status ? <div className="text-info">有効</div> : <div className="text-danger">無効</div>}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default App
