import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import Table from "react-bootstrap/Table"
import * as api from "../../lib/api"
import { ILicense, ICategory, IAccount } from "../../lib/interface"
import LinkTreeCategory from "../category/_link_tree_category"

import FormModal from "./_form_modal"

function App() {
  const [allCategories, setAllCategories] = useState<ICategory[] | null>(null)
  const [license, setLicense] = useState<ILicense>(null)
  const params = useParams<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [formModalShow, setFormModalShow] = useState(false)
  //modalで表示するformのタイプを保持する
  const [formType, setFormType] = useState<string>("")

  useEffect(() => {
    const id = params.id
    getLicense(id)
    getAllCategories()
  }, [])

  const getLicense = async (id: string) => {
    try {
      const licenseJson = await api.getLicenseDb(id)
      if (licenseJson?.data) {
        console.log(licenseJson.data)
        setLicense(licenseJson.data)
      }
      setIsLoaded(false)
    } catch (err) {
      console.log(err.message || err)
    }
  }

  const getAllCategories = async () => {
    const json = await api.getCategoriesDb("", "", "")
    setAllCategories(json?.data)
  }

  const handleModalShow = (type: string) => {
    setFormType(type)
    setFormModalShow(true)
  }

  return (
    <React.Fragment>
      {isLoaded && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && license && (
        <>
          <FormModal
            license={license}
            getLicense={getLicense}
            formType={formType}
            show={formModalShow}
            setFormModalShow={setFormModalShow}
            onHide={() => setFormModalShow(false)}
          />
          <h1 className="h4">ライセンス詳細</h1>
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/license/">ライセンス一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{license?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <p>無効且つ利用ユーザおよび許可カテゴリがないライセンスのみ削除可能です。</p>
          <Table className="user-table">
            <tbody>
              <tr>
                <th>ステータス</th>
                <td>
                  {license.status ? <div className="text-info">有効</div> : <div className="text-danger">無効</div>}
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("status")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>{license?.viewNumber}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("viewNumber")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>名前</th>
                <td>{license?.name}</td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("name")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>更新日</th>
                <td colSpan={2}>{license?.updateDate}</td>
              </tr>
              <tr>
                <th>許可カテゴリ</th>
                <td>
                  <div className="m-3">
                    <LinkTreeCategory
                      linkMode={false}
                      allCategories={allCategories}
                      topCategories={allCategories?.filter((category: ICategory) => {
                        return !category.parentId
                      })}
                      targetIds={license.categories?.map((category: ICategory) => {
                        return category.id
                      })}
                    />
                  </div>
                </td>
                <td>
                  <Button
                    size="sm"
                    className="text-right"
                    onClick={() => {
                      handleModalShow("categories")
                    }}
                  >
                    変更
                  </Button>
                </td>
              </tr>
              <tr>
                <th>利用ユーザ</th>
                <td colSpan={2}>
                  {license.users?.map((user: IAccount, index: number) => {
                    return (
                      <div key={`userDetail-${index}`}>
                        <Link
                          to={`/user/${user.cognitoId}/`}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="btn-sm"
                        >
                          {user.name}
                        </Link>
                      </div>
                    )
                  })}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-6 text-right">
              <Button href={`/license/`} className="btn-secondary btn-sm">
                一覧へ戻る
              </Button>
            </div>
            <div className="col-6 text-right">
              {license.status == 0 && !license.categories?.length && !license.users?.length && (
                <Button
                  onClick={() => {
                    handleModalShow("delete")
                  }}
                  className="btn-sm btn-block"
                  variant="danger"
                >
                  削除
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default App
